import React, { useRef, useState } from 'react';
import { useImmer } from 'use-immer';
import _ from 'lodash';
import { BreadcrumbBar, ElementLabel, ErrorOverlay, FlexBox, Heading2, PageTitle, ResetZIndex, Section, Wrapper, WrapperContent, Text } from '@liasincontrol/ui-basics';
import { AxiosResponse } from 'axios';
import { Finance as DataAccess, oDataResponseStructured } from '@liasincontrol/data-service';
import { ApiErrorReportingHelper, DataUtils, useUserSettings } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { ContextMenu, Button as DxButton, generateSafeId, GridColumn, LoadPanel, LsGrid, LsGridToolbarItem, SelectBoxField, SummaryGroupItem } from '@liasincontrol/ui-devextreme';
import { AjaxRequestStatus } from '@liasincontrol/redux-service';
import { costBenefitFilterItems, Filter } from './Filter';
import { useJournalElementKinds } from '../../shared/hooks';
import { Transactions, TransactionsType } from './Transactions';
import { useStructureNodes } from '../../shared/hooks/useStructureNodes';

type BreadCrumbDataType = {
    kind?: Domain.Finance.JournalElementKind,
    value?: Domain.Finance.BudgetOverviewValue // raw value
    returnedView?: keyof typeof overviewTypeDictionary, //What view is requested on clicking in BC
    hidden?: boolean,
};

type GridDataType = {
    data: Domain.Finance.BudgetOverviewValue[];
    availableColumns: GridColumn<Domain.Finance.BudgetOverviewValue>[];
    groupSummaries: SummaryGroupItem[];
    totalSummaries: SummaryGroupItem[];
};
/**
 * Represents a UI component that renders the overview page of the budgets.
 */

const overviewTypeDictionary = {
    Elements: {
        name: 'Elements',
        keyExpr: 'journalElementRK',
        displayExpr: 'journalElementCode',
    },
    StructureNode: {
        name: 'StructureNode',
        keyExpr: 'structureNodeRK',
        displayExpr: 'structureNodeCode',
    },
    Combinations: {
        name: 'Combinations',
        keyExpr: undefined,
        displayExpr: undefined,
    },
};

const mapColumns = (layout, divideBy, grouping) => {
    const columns = layout?.map(c => ({
        title: c.caption,
        columns: c.columns.map(col => ({
            name: changeCaseFirstLetter(col.dataField),
            title: col.caption,
            hideInColumnChooser: !col.caption,
            isClickable: col.isClickable,
            ...mapColumnProperties(col, divideBy, grouping),
        })),
    }));
    return columns;
};

const mapSummaries = (layout, divideBy) => {
    const summaries: { column: string, summaryType: string }[] = layout?.map(c =>
        c.columns.map(col => {
            if (col.dataField && DataUtils.mapStringToDxType(col.dataType, false, false) === 'number')
                return {
                    column: changeCaseFirstLetter(col.dataField),
                    summaryType: "sum",
                    alignByColumn: true,
                    skipEmptyValues: true,
                    customizeText: (itemInfo) => `${formatText(itemInfo, divideBy)}`
                };
            return null;
        })).flat().filter(Boolean);
    return summaries;
};

//For jurnal element kinds and structure nodes, add context menu items
const mapColumnExtraProperties = (view,
    keyExpr: string,
    availableItems: Domain.Finance.JournalElementKind[] | Domain.Finance.StructureNode[] = [],
    onContextClick?: (selectedData: Domain.Finance.BudgetOverviewValue, selectedItem?: Domain.Finance.JournalElementKind, view?: keyof typeof overviewTypeDictionary) => void) => {

    switch (view) {
        case 'Elements': {
            if (!availableItems.length) return;
            return {
                'renderCustom': ({ data }) => (
                    <ContextMenu<Domain.Finance.BudgetOverviewValue>
                        item={data}
                        keyExpr={keyExpr}
                        uniqueId={`${data[keyExpr]}-${data['transactionKind']}`}
                        actions={availableItems.map(
                            (item) => {
                                return {
                                    action: (data) => { return onContextClick(data, item); },
                                    ariaLabel: `Drilldown journaal ${item.rk}`,
                                    actionName: `drilldown-budgetJournal-${item.rk}`,
                                    displayName: `${item.name}`
                                };
                            }).concat(
                                {
                                    action: (data) => { return onContextClick(data, undefined, 'Combinations'); },
                                    ariaLabel: `Drilldown combinaties ${data[keyExpr]}`,
                                    actionName: `drilldown-structurenode-combinations-${data[keyExpr]}`,
                                    displayName: 'Combinatie'
                                }
                            )}
                    />)
            }
        }
        case 'StructureNode': {
            return {
                'renderCustom': ({ data }) => (
                    <ContextMenu<Domain.Finance.BudgetOverviewValue>
                        item={data}
                        keyExpr={keyExpr}
                        uniqueId={`${data[keyExpr]}-${data['transactionKind']}`}
                        actions={[
                            {
                                action: (data) => { return onContextClick(data); },
                                ariaLabel: `Drilldown struktuurelement ${data[keyExpr]}`,
                                actionName: `drilldown-structurenode-${data[keyExpr]}`,
                                displayName: 'Structuurelement',
                                hidden: availableItems.filter(item => item['parentRK'] === data.structureNodeRK).length === 0,
                            },
                            {
                                action: (data) => { return onContextClick(data, undefined, 'Elements'); },
                                ariaLabel: `Drilldown elementkinds ${data[keyExpr]}`,
                                actionName: `drilldown-structurenode-kinds-${data[keyExpr]}`,
                                displayName: 'Boekingselement'
                            },
                            {
                                action: (data) => { return onContextClick(data, undefined, 'Combinations'); },
                                ariaLabel: `Drilldown combinaties ${data[keyExpr]}`,
                                actionName: `drilldown-structurenode-combinations-${data[keyExpr]}`,
                                displayName: 'Combinatie'
                            }
                        ]}
                    />)
            };
        }
        case 'Combinations':
        default: return;
    }
};

/**
 * The `Overview` component provides a detailed view of the budget overview, allowing users to filter,
 * drill down into specific data, and view summaries. It fetches and displays data based on user settings
 * and interactions, such as selecting different elements or structure nodes.
 */
export const Overview: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const { baseYear, structureRK, structureNodeRK } = useUserSettings();
    const journalElementKinds = useJournalElementKinds();
    const { structureNodes } = useStructureNodes();
    const [costBenefitFilter, setCostBenefitFilter] = useState<string[]>(costBenefitFilterItems.flatMap(cb => cb.value));
    const [gridData, setGridData] = useImmer<GridDataType>({ data: [], availableColumns: [], groupSummaries: [], totalSummaries: [] });
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [breadCrumb, setBreadCrumb] = useState<BreadCrumbDataType[]>([]);
    const [view, setView] = useState<keyof typeof overviewTypeDictionary>();
    const [loadingTransactions, setLoadingTransactions] = useState(false);
    const [transactions, setTransactions] = useState<TransactionsType>();

    const divideBy = useRef(1);

    if (journalElementKinds.status !== AjaxRequestStatus.Done) return <></>;

    const fetchDrillDown = (
        selectedData: Domain.Finance.BudgetOverviewValue,
        selectedBreadCrumb: BreadCrumbDataType[],
        hideInBreadcrumb = false,
        overviewType: keyof typeof overviewTypeDictionary,
        selectedItem?: Domain.Finance.JournalElementKind
    ) => {
        setLoading(true);
        const lastKnownTransactionKind = getLastKnownTransactionKind(selectedData, selectedBreadCrumb);
        let contextMenuAvailableItems = [];
        let journalElementRoute = [];

        if (overviewType === 'Elements' || overviewType === 'Combinations') {
            contextMenuAvailableItems = journalElementKinds.items
                .filter(obj1 =>
                    obj1?.rk !== selectedItem?.rk &&
                    !selectedBreadCrumb.flatMap(o => o.kind ? o.kind : []).some(obj2 => obj1?.rk === obj2.rk)
                );
            journalElementRoute = selectedBreadCrumb.map(bc => bc.value?.journalElementRK)
                .filter(journalElementRK => journalElementRK !== undefined)
                .concat(selectedData?.journalElementRK)
                .filter(x => !!x);
        }
        if (overviewType === 'StructureNode') {
            //TODO filter out for subtree of selected data should help
            contextMenuAvailableItems = structureNodes[structureRK].items;
        }

        const worker = getWorker(overviewType, selectedItem, lastKnownTransactionKind, journalElementRoute, selectedData);

        worker.then((response) => {
            const columns = mapColumns(response.data.layout, divideBy.current, false);
            const summaries = mapSummaries(response.data.layout, divideBy.current);
            const newBreadCrumbItem: BreadCrumbDataType = {
                value: selectedData, returnedView: overviewType, hidden: hideInBreadcrumb
            };

            if (overviewType === 'Elements' && !selectedItem) {
                //drilldown from node to elements
                const currentKind = journalElementKinds.items.find(kind => kind.name === columns[0]?.title);
                contextMenuAvailableItems = contextMenuAvailableItems.filter(i => i.rk !== currentKind?.rk);
                newBreadCrumbItem.kind = currentKind;
            } else {
                newBreadCrumbItem.kind = selectedItem;
            }

            const updatedBreadCrumb = selectedBreadCrumb.concat(newBreadCrumbItem);
            setGridData(draft => {
                // Update availableColumns
                draft.availableColumns.length = 0;
                if (columns) draft.availableColumns.push(...columns);
                if (draft.availableColumns[0]?.columns?.[0]) {
                    _.set(draft.availableColumns, '[0].columns[0]', {
                        ...draft.availableColumns[0].columns[0],
                        ...mapColumnExtraProperties(
                            overviewType,
                            overviewTypeDictionary[overviewType].keyExpr,
                            contextMenuAvailableItems,
                            (data, item, destView) => fetchDrillDown(data, updatedBreadCrumb, false, destView ?? overviewType, item)
                        )
                    });
                }

                // Update groupSummaries
                draft.groupSummaries = [];

                // Update totalSummaries
                draft.totalSummaries = summaries;

                // Update data
                draft.data = response.data.values;
            });
            setBreadCrumb(updatedBreadCrumb);
            setTransactions(null);

        }).catch((error) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, error))
        }).finally(() => {
            setView(overviewType);
            setLoading(false);
        });
    };

    //#region drilldown helpers
    const getLastKnownTransactionKind = (selectedData, selectedBreadCrumb) => {
        return selectedData?.transactionKind
            ? selectedData.transactionKind
            : selectedBreadCrumb.flatMap(bc => bc.value)?.find(bcv => bcv?.transactionKind)?.transactionKind;
    };

    // If drilldown comes from structurenode, not journalelementRk route exists
    const getWorker = (overviewType, selectedItem, lastKnownTransactionKind, journalElementRoute, selectedData) => {
        switch (overviewType) {
            case 'Elements':
                return selectedItem && !_.isEmpty(journalElementRoute)
                    ? DataAccess.BudgetOverview.getJournalElementKindDrilldown(baseYear, selectedItem.rk, lastKnownTransactionKind, journalElementRoute)
                    : DataAccess.BudgetOverview.getV2(baseYear, structureRK, selectedData.structureNodeRK as string, [lastKnownTransactionKind]);
            case 'Combinations':
                return _.isEmpty(journalElementRoute)
                    ? DataAccess.BudgetOverview.getJournalCombinationDrilldownNode(baseYear, structureRK, lastKnownTransactionKind, selectedData.structureNodeRK as string)
                    : DataAccess.BudgetOverview.getJournalCombinationDrilldown(baseYear, lastKnownTransactionKind, journalElementRoute);
            case 'StructureNode':
                return DataAccess.BudgetOverview.getBudgetOverviewStructureNodes(baseYear, structureRK, [lastKnownTransactionKind], selectedData.structureNodeRK as string);
            default:
                return Promise.resolve({ data: { layout: [], values: [] } });
        }
    };
    //#endregion

    // nodes or elements overview, never directly combination
    const filterOverview = () => {
        if (!baseYear || !structureRK) return;
        setLoading(true);
        setBreadCrumb([]);
        setTransactions(null);

        const [worker, view]: [Promise<AxiosResponse<oDataResponseStructured<Domain.Finance.BudgetOverviewValue>>>, keyof typeof overviewTypeDictionary] = !structureNodeRK
            ? [DataAccess.BudgetOverview.getBudgetOverviewStructureNodes(baseYear, structureRK, costBenefitFilter), 'StructureNode']
            : [DataAccess.BudgetOverview.getV2(baseYear, structureRK, structureNodeRK, costBenefitFilter), 'Elements'];
        setView(view);

        worker.then((response) => {
            const columns = mapColumns(response.data.layout, divideBy.current, true);
            const summaries = mapSummaries(response.data.layout, divideBy.current);

            const updatedBreadCrumb: BreadCrumbDataType[] = [];
            let contextMenuAvailableItems = [];
            if (view === 'Elements') {
                //find current element kind, to eliminate it from the context menu
                const currentKind = journalElementKinds.items.find(kind => kind.name === columns[0]?.title);
                updatedBreadCrumb.push({ kind: currentKind, value: undefined, returnedView: view, hidden: false });
                contextMenuAvailableItems = journalElementKinds.items.filter(i => i.rk !== currentKind?.rk);
            } else if (view === 'StructureNode') {
                contextMenuAvailableItems = structureNodes[structureRK].items;
                updatedBreadCrumb.push({ kind: undefined, value: undefined, returnedView: view, hidden: false });
            }
            setGridData(draft => {
                // Update availableColumns
                draft.availableColumns.length = 0;
                if (columns) draft.availableColumns.push(...columns);
                if (draft.availableColumns[0]?.columns[0]) {
                    draft.availableColumns[0].columns[0] = {
                        ...draft.availableColumns[0].columns[0],
                        ...mapColumnExtraProperties(
                            view,
                            overviewTypeDictionary[view].keyExpr,
                            contextMenuAvailableItems,
                            (data, item, destView) => fetchDrillDown(data, updatedBreadCrumb, false, destView ?? view, item)
                        ),
                    };
                }

                // Update groupSummaries
                draft.groupSummaries = [];

                // Update totalSummaries
                draft.totalSummaries = summaries;

                // Update data
                draft.data = response.data.values;
            });

            setBreadCrumb(updatedBreadCrumb);
        }).catch((error) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, error))
        }).finally(() => {
            setLoading(false);
        });
    };

    const onCellClick = (item, _value, column) => {
        const transactionKind = getLastKnownTransactionKind(item, breadCrumb);

        const transactionColumnWasClicked = gridData.availableColumns.some(colGroup =>
            colGroup.columns.some(col => col.name === column.dataField && col.isClickable)
        );
        if (transactionColumnWasClicked) {
            const lastBreadcrumbItem = breadCrumb[breadCrumb.length - 1];
            const updatedBreadCrumb = [...breadCrumb, { kind: lastBreadcrumbItem?.kind, value: item }];
            const amountType = changeCaseFirstLetter(column.dataField, false);
            const journalElementRoute = updatedBreadCrumb.reduce((acc, bc) => {
                if (bc.value?.journalElementRK !== undefined) {
                    acc.push(bc.value.journalElementRK);
                }
                return acc;
            }, []);

            if (view === 'Combinations') {
                //rk on the returned + rk on the visited
                const additionalRoute = Object.keys(item).reduce((acc, key) => {
                    if (key.startsWith('journalElement') && key.endsWith('RK')) {
                        acc.push(item[key]);
                    }
                    return acc;
                }, []);
                journalElementRoute.push(...additionalRoute);
            }

            setLoadingTransactions(true);
            const worker = view === 'StructureNode'
                ? DataAccess.BudgetOverview.getNodeTransactions(structureRK, item[overviewTypeDictionary[view].keyExpr], transactionKind, amountType)
                : DataAccess.BudgetOverview.getTransactions(journalElementRoute, transactionKind, amountType)

            worker.then((response) => {
                setTransactions({
                    gridTitle: view !== 'Combinations' ? `${item[overviewTypeDictionary[view].displayExpr]} ${column.caption}` : '',
                    layout: response.data.layout,
                    data: response.data.values
                });
            }).catch((error) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, error))
            }).finally(() => {
                setLoadingTransactions(false);
            });
            return;
        }
    };

    const lastVisibleBreadCrumbIndex = breadCrumb.findLastIndex(item => !item.hidden);

    return (
        <Wrapper>
            <WrapperContent>
                <PageTitle>
                    <Heading2>Budgetoverzicht</Heading2>
                </PageTitle>
                <ErrorOverlay error={error?.message} errorDetails={error?.details} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                    <Section look='white'>
                        <Filter onFilter={() => { divideBy.current = 1; filterOverview(); }} costBenefitFilter={costBenefitFilter} setCostBenefitFilter={setCostBenefitFilter} disabled={loading} />
                    </Section>
                    <Section look="white" fixedWidth={true}>
                        <ResetZIndex>
                            <BreadcrumbBar>
                                {breadCrumb.length > 1 && breadCrumb.map((bv, index) => {
                                    if (bv.hidden) return null;
                                    const [bctext, hinttext, safeId] = bv.value
                                        ? bv.value.journalElementCode
                                            ? [
                                                bv.value.journalElementCode,
                                                `${bv.value.journalElementCode} - ${bv.value.journalElementName} \n ${breadCrumb[index - 1]?.kind?.rk} - ${breadCrumb[index - 1]?.kind?.name}`,
                                                generateSafeId(bv.value.journalElementCode)
                                            ]
                                            : [
                                                bv.value.structureNodeCode,
                                                `${bv.value.structureNodeCode} - ${bv.value.structureNodeName}`,
                                                generateSafeId(bv.value.structureNodeCode as string)
                                            ]
                                        : ['', '', ''];
                                    return <DxButton
                                        key={`key-btn-${index > 0 ? safeId : 'overzicht'}`}
                                        id={`breadcrumb-btn-${index > 0 ? safeId : 'overzicht'} `}
                                        className='lias-button'
                                        type="default"
                                        stylingMode="text"
                                        icon={index === 0 ? "home" : undefined}
                                        text={index > 0 ? `${bctext}` : undefined}
                                        disabled={index === lastVisibleBreadCrumbIndex}
                                        hint={index > 0 ? `${hinttext}` : 'Overzicht'}
                                        onClick={() => {
                                            if (index === 0) {
                                                filterOverview();
                                            } else {
                                                fetchDrillDown(bv.value, breadCrumb.slice(0, index), false, bv.returnedView, bv.kind);
                                            }
                                        }}
                                    />
                                })}
                            </BreadcrumbBar>
                            {loading ?
                                <LoadPanel visible={loading} />
                                :
                                gridData.data?.length ? <LsGrid
                                    keyExpr={overviewTypeDictionary[view].keyExpr}
                                    searching={true}
                                    showRowLines={true}
                                    showColumnLines={true}
                                    showBorders={true}
                                    allowColumnResizing={true}
                                    columnAutoWidth={false}
                                    enableColumnChooser={true}
                                    columns={gridData.availableColumns}
                                    dataSource={gridData.data}
                                    onDataError={(exception) => setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception))}
                                    onClickCell={onCellClick}
                                    showCellHintText={true}
                                    getHintText={(columnField: string, value: string) => {
                                        if (columnField) return value;
                                        return;
                                    }}
                                    customToolbarItems={[
                                        <LsGridToolbarItem location="before" locateInMenu="auto">
                                            <FlexBox>
                                                <ElementLabel className='mr-050'>Delen door</ElementLabel>
                                                <SelectBoxField<number>
                                                    id='input-divide-by'
                                                    optionItems={divideByOptions}
                                                    clearable={false}
                                                    searchable={false}
                                                    withoutFeedback={true}
                                                    onChange={(e) => {
                                                        divideBy.current = e;
                                                        setGridData(draft => {
                                                            // Update availableColumns
                                                            draft.availableColumns.forEach((col) => {
                                                                if (col.columns) {
                                                                    col.columns.forEach((c) => {
                                                                        c.customizeText = (cellInfo) => formatText(cellInfo, e);
                                                                    });
                                                                }
                                                            });

                                                            // Update groupSummaries
                                                            draft.groupSummaries.forEach((sum) => {
                                                                sum.customizeText = (cellInfo) => formatText(cellInfo, e);
                                                            });

                                                            // Update totalSummaries
                                                            draft.totalSummaries.forEach((sum) => {
                                                                sum.customizeText = (cellInfo) => formatText(cellInfo, e);
                                                            });
                                                        });
                                                    }}
                                                    value={divideBy.current} />
                                            </FlexBox>
                                        </LsGridToolbarItem>,
                                        <LsGridToolbarItem
                                            location="after"
                                            locateInMenu="auto"
                                            widget="dxDropDownButton"
                                            visible={breadCrumb.length > 1 && (breadCrumb[breadCrumb.length - 1].hidden || !breadCrumb[breadCrumb.length - 1].kind)}
                                            options={{
                                                stylingMode: "text",
                                                displayExpr: "text",
                                                keyExpr: "id",
                                                useSelectMode: true,
                                                items: [
                                                    { id: 'Elements', text: "Boekingselement" },
                                                    { id: 'StructureNode', text: "Structuurelement" },
                                                    { id: 'Combinations', text: "Combinatie" },
                                                ],
                                                selectedItemKey: view,
                                                onSelectionChanged: (e): void => {
                                                    const lastBreadcrumbItem = breadCrumb[breadCrumb.length - 1];
                                                    //No need bredcrumb update, just change view
                                                    fetchDrillDown(lastBreadcrumbItem.value, breadCrumb, true, e.item.id, lastBreadcrumbItem?.kind);
                                                },
                                            }}
                                        >
                                        </LsGridToolbarItem>
                                    ]}
                                    remoteOperations={{ summary: false, }}
                                    groupSummaries={gridData.groupSummaries}
                                    totalSummaries={gridData.totalSummaries}
                                /> : <Text value='Voer een zoekopdracht uit om het budgetoverzicht te raadplegen.' />}
                        </ResetZIndex>
                    </Section>

                    <Transactions transactions={transactions} loading={loadingTransactions} divideBy={divideBy.current} />
                </ErrorOverlay>
            </WrapperContent>
        </Wrapper >
    );
};

const divideByOptions = [1, 1000];

export const formatText = (cellInfo, divideBy) => {
    // Format the value using European formatting
    const value = Number(cellInfo.value);
    if (!isNaN(value)) {
        const dividedValue = value / divideBy;
        return dividedValue.toLocaleString('nl-NL', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });
    }
    return cellInfo.value; // Return original value if it's not a number
};

export const mapColumnProperties = (column, divideBy = 1, grouping = true) => {
    const colProps: GridColumn<any> = {
        align: DataUtils.getDefaultDxAlignment(column.dataType),
        dataType: DataUtils.mapStringToDxType(column.dataType, false, false),
    };

    if (colProps.dataType === 'number') {
        colProps.customizeText = (cellInfo) => formatText(cellInfo, divideBy);
    }

    //specific requests for column properties
    if (!/(name)$/i.test(column.dataField)) {
        colProps.width = "auto";
    } else {
        colProps.minWidth = 150;
    }

    if (grouping && /(transactionkind)$/i.test(column.dataField)) {
        colProps['groupIndex'] = 0;
        colProps['groupCellRender'] = (data) => data.value;
        colProps['hideInColumnChooser'] = true;
    }

    if (!column.caption) {
        colProps.align = 'center';
    }

    return colProps;
};

const changeCaseFirstLetter = (sourceText: string, toLower = true) => {
    if (!sourceText) return;
    if (toLower) return sourceText.charAt(0).toLowerCase() + sourceText.slice(1);
    return sourceText.charAt(0).toUpperCase() + sourceText.slice(1);
};

export { Overview as index };
