import React, { useEffect, useState } from 'react';
import { Button, LayoutField, LayoutForm } from '@liasincontrol/ui-basics';
import { TextElement } from '@liasincontrol/ui-elements';
import * as Domain from '@liasincontrol/domain';
import { FormHelper, TextValidator, ValidationErrorData } from '@liasincontrol/core-service';
import _ from 'lodash';

type PageImageSettingsProps = {
    readonly sitemapNode: Domain.Publisher.SitemapNode,
    readonly disabled: boolean,
    readonly updateTitle: (newTitle: string) => Promise<void>,
    readonly isEditing: () => void,
}

export const PageNameSettings: React.FC<PageImageSettingsProps> = (props) => {
    const [pageName, setPageName] = useState<{ isEditing: boolean, value: string }>({ isEditing: false, value: props.sitemapNode.elementName });
    const [validationErrors, setValidationErrors] = useState<{
        errors: Record<string, ValidationErrorData[]>,
        hasErrors: boolean,
    }>({ errors: {}, hasErrors: false });

    useEffect(() => {
        if (props.sitemapNode.elementName === pageName.value) return;

        setPageName({ isEditing: false, value: props.sitemapNode.elementName });
    }, [props.sitemapNode]);

    const onSaveNewPageName = (pageName: string) => {
        props.updateTitle(pageName)
            .then(() => setPageName({ isEditing: false, value: pageName }));
    };

    return (
        <LayoutForm align='center'>
            <LayoutField left={1} top={1} width={5} height={1}>
                <TextElement
                    id='title-field'
                    label='Pagina'
                    editorSettings={{
                        disabled: !pageName.isEditing,
                        validationErrors: validationErrors.errors["pageTitle"],
                        restrictions: { required: true, minLength: 2, maxLength: 50 },
                        onChange: (value: string) => {
                            if (value === props.sitemapNode.elementName) {
                                return;
                            }
                            setPageName({ isEditing: true, value });
                            const temporaryValidationError = _.cloneDeep(validationErrors);
                            const validationResult = validatePageTitle(value, validationErrors.errors);
                            temporaryValidationError.errors['pageTitle'] = validationResult.errors['pageTitle'];
                            temporaryValidationError.hasErrors = validationResult.hasErrors;
                            setValidationErrors(temporaryValidationError);
                        },
                    }}
                    value={pageName.value}
                />
            </LayoutField>
            <LayoutField left={6} top={1} width={1} height={1} >
                {pageName.isEditing ?
                    <Button
                        id="btn-icon-save-title"
                        btnbase="ghostbuttons"
                        btntype="small_noicon"
                        disabled={validationErrors.hasErrors}
                        onClick={() => onSaveNewPageName(pageName.value)}
                    >
                        Opslaan
                    </Button>
                    : <Button
                        id="btn-icon-change-title"
                        btnbase="ghostbuttons"
                        btntype="small_noicon"
                        disabled={props.disabled}
                        onClick={() => {
                            setPageName({ isEditing: true, value: props.sitemapNode.elementName });
                            props.isEditing?.();
                        }}
                    >
                        Bewerken
                    </Button>}
            </LayoutField>
        </LayoutForm>
    );
};

const validatePageTitle = (pageTitle: string, errors: Record<string, ValidationErrorData[]>) => {
    return FormHelper.validateForm(validators, { pageTitle }, errors);
};

const validators = {
    'pageTitle': new TextValidator({ required: true, stringMaxLength: 50, stringType: Domain.Shared.StringType.SingleLine }),
};
