import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ValidationUtils, FormData, ValueType, FieldsHelper, TextValidator, BasicValidator, ValidatorsDictionary, FormHelper, DefinitionsHelper } from '@liasincontrol/core-service';
import { IDataItemProps, LayoutField, LayoutForm, ModalDialog, ModalDialogFooter, RadioGroupField } from '@liasincontrol/ui-basics';
import { LookupEditor, TemplateSelectElement, TextElement } from '@liasincontrol/ui-elements';
import { SystemFieldDefinitions } from '@liasincontrol/domain';
import { DxCustomStore, DxDataSource, FieldNameOf } from '@liasincontrol/ui-devextreme';
import * as Domain from '@liasincontrol/domain';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import { usePublicationSettings } from '../../../../../../helpers/PublicationContext';
import buildQuery from 'odata-query';

type Props = {
    publicationElementDefinition: Domain.Shared.ElementDefinition,
    templateElementDefinition: Domain.Shared.ElementDefinition,
    onSave: (template: Domain.Publisher.TemplateElement) => void,
    onError: (err) => void,
    onCancel: () => void,
};

/**
 * Represents a UI component that renders the dialog for creating or importing a template.
 */
const TemplateAdd: React.FC<Props> = (props) => {
    const { id } = useParams<{ id: string }>();
    const [form, setForm] = useState<FormData<string>>(initForm());
    const [publicationElement, setPublicationElement] = useState<Domain.Publisher.PublicationElement>();
    const [pageDesigns, setPageDesigns] = useState<Domain.Publisher.PageDesign[]>([]);
    const [shouldImportTemplate, setShouldImportTemplate] = useState<IDataItemProps<boolean>>(radioOptions[0]);
    const publication = usePublicationSettings();
    const [selectedPublicationId, setSelectedPublicationId] = useState<string>();
    const [selectedTemplateId, setSelectedTemplateId] = useState<string>();
    const [isSaving, setIsSaving] = useState(false);

    const customPublicationsDataSource = useMemo(() => {
        const source = new DxDataSource({
            paginate: true,
            pageSize: 5,
            store: new DxCustomStore({
                key: 'value',
                load: (loadOptions) => {
                    if (!loadOptions) {
                        return Promise.resolve([]);
                    }
                    const escapedSearchTerm = !!loadOptions.searchValue ? `${encodeURIComponent(`"${loadOptions.searchValue.replace(/[\\"]/g, '\\$&')}"`)}` : '';

                    const query = buildQuery({
                        search: escapedSearchTerm,
                        filter: { isClosed: false },
                        select: Object.keys(new Domain.Publisher.PublicationV2()),
                        top: loadOptions.take,
                        count: true,
                        skip: loadOptions.skip,
                    });
                    return DataAccess.Publications.getPublicationsV2(query)
                        .then((response) => {
                            const list = response.data.value?.map((publication) => {
                                if (publication.publicationId === id) {
                                    setSelectedPublicationId(publication.publicationId);
                                }

                                return { value: publication.publicationId, label: publication.name };
                            });
                            return { data: list };
                        });
                },
            }),
        });
        return source;
    }, []);

    const customTemplatesDataSource = useMemo(() => {

        const source = new DxDataSource({
            paginate: true,
            pageSize: 5,
            store: new DxCustomStore({
                key: 'value',
                load: (loadOptions) => {
                    if (!loadOptions || !selectedPublicationId) {
                        return Promise.resolve([]);
                    }
                    const fieldsToDisplay: FieldNameOf<Domain.Publisher.TemplateElement>[] = ['name', 'id'];

                    const selectedColumns = DefinitionsHelper.getSelectableFieldsForAPI(new Domain.Publisher.TemplateElement(), fieldsToDisplay, props.templateElementDefinition);
                    const escapedSearchTerm = !!loadOptions.searchValue ? `${encodeURIComponent(`"${loadOptions.searchValue.replace(/[\\"]/g, '\\$&')}"`)}` : '';

                    const query = buildQuery({
                        filter: null,
                        search: escapedSearchTerm,
                        select: selectedColumns.join(','),
                        top: loadOptions.take,
                        count: true,
                        skip: loadOptions.skip,
                    });
                    return DataAccess.Publications.getTemplates(selectedPublicationId, query)
                        .then((response) => {
                            const list = response.data.value?.map((template) => {
                                const templateElement = new Domain.Publisher.TemplateElement();
                                FieldsHelper.mapObject<Domain.Publisher.TemplateElement>(templateElement, props.templateElementDefinition.fields, template);

                                return { value: template.id, label: templateElement.name };
                            })

                            return { data: list };
                        });
                },
            }),
        });

        return source;
    }, [selectedPublicationId, props.templateElementDefinition.fields, customPublicationsDataSource]);


    useEffect(() => {
        if (props.publicationElementDefinition && publication) {
            const settings = new Domain.Publisher.PublicationElement();
            FieldsHelper.mapObject<Domain.Publisher.PublicationElement>(settings, props.publicationElementDefinition.fields, publication.publication.fields);
            setPublicationElement(settings);
        }
    }, [publication, props.publicationElementDefinition]);

    useEffect(() => {
        if (publicationElement) {
            DataAccess.SiteDesigns.getPageDesigns(publicationElement.siteDesignId).then((response) => {
                setPageDesigns(response.data);
            });
        }
    }, [publicationElement]);

    const storeFormValue = (value: ValueType, systemId: keyof typeof validators) => {
        setForm((prevForm) => FormHelper.validateAndStoreFormValue<FormData<string>>(prevForm, value, validators, systemId));
    };

    const onCreateTemplate = () => {
        const fields = {
            [templateFieldDefinitions[SystemFieldDefinitions.Pub.Name].id]: form.values[SystemFieldDefinitions.Pub.Name],
            [templateFieldDefinitions[SystemFieldDefinitions.Pub.PageDesignId].id]: form.values[SystemFieldDefinitions.Pub.PageDesignId],
        };
        const templateElement = { elementDefinitionId: props.templateElementDefinition.id, fields: fields } as Domain.Publisher.Element;
        DataAccess.Templates.createPageTemplate(publication.publication.elementId, templateElement)
            .then(response => {
                const newTemplate = new Domain.Publisher.TemplateElement();
                newTemplate.id = response.data;
                newTemplate.pageDesignId = form.values[SystemFieldDefinitions.Pub.PageDesignId];
                newTemplate.name = form.values[SystemFieldDefinitions.Pub.Name];
                props.onSave(newTemplate);
            }).catch(props.onError);
    };

    const onImportTemplate = () => {
        const sourceTemplate = {
            name: form.values[SystemFieldDefinitions.Pub.Name],
            sourcePublicationId: selectedPublicationId,
            sourcePageTemplateId: selectedTemplateId
        };

        DataAccess.Templates.clonePageTemplate(id, sourceTemplate)
            .then(() => {
                props.onSave(null);
            }).catch(props.onError);
    };

    const onSave = (): void => {
        if (isSaving) return;
        setIsSaving(true);
        if (!shouldImportTemplate.value) {
            onCreateTemplate();
        } else {
            onImportTemplate();
        }
    };

    const templateFieldDefinitions = props.templateElementDefinition?.fields?.reduce(
        (collection, item) => ({ ...collection, [item.systemId]: item }),
        {}
    ) as Record<string, Domain.Shared.FieldDefinition>;

    const nameFieldDefinition = templateFieldDefinitions[SystemFieldDefinitions.Pub.Name];
    const pageDesignFieldDefinition = templateFieldDefinitions[SystemFieldDefinitions.Pub.PageDesignId];
    const validators = getValidators(templateFieldDefinitions);

    const dialogFooter: JSX.Element = (
        <ModalDialogFooter
            leftButtonText='Annuleren'
            onLeftButtonClick={props.onCancel}
            rightButtonText='Aanmaken'
            onRightButtonClick={onSave}
            rightButtonDisabled={!form.isValid || Object.keys(form.touched).length === 0 || isSaving} />
    );

    return (
        <ModalDialog settings={{ look: 'interactive', title: 'Sjabloon aanmaken', width: 1024, footer: dialogFooter }}>
            <LayoutForm>
                <LayoutField left={1} top={1} width={3} height={1} key={nameFieldDefinition.id}>
                    <TextElement
                        id={nameFieldDefinition.id}
                        label={nameFieldDefinition.label ? nameFieldDefinition.label : nameFieldDefinition.name}
                        editorSettings={ValidationUtils.getEditorSettings(true, false, validators, form, (val: string) => { storeFormValue(val, SystemFieldDefinitions.Pub.Name) }, SystemFieldDefinitions.Pub.Name)}
                        value={form.values[SystemFieldDefinitions.Pub.Name]} />
                </LayoutField>
                <LayoutField left={1} top={2} width={3} height={2}>
                    <RadioGroupField
                        id='input-import-template'
                        label='Sjabloon importeren'
                        items={radioOptions}
                        value={shouldImportTemplate.value}
                        onChange={(v) => setShouldImportTemplate(radioOptions.find(o => o.value === v))}
                    />
                </LayoutField>
                {shouldImportTemplate.value &&
                    <>
                        <LayoutField left={1} top={4} width={3} height={1}>
                            <LookupEditor
                                id='select-publication-source'
                                dataSource={customPublicationsDataSource}
                                label="Publicatie"
                                placeholder="Kies…"
                                displayExpr='label'
                                valueExpr='value'
                                value={selectedPublicationId}
                                editorSettings={{
                                    clearable: true,
                                    onChange: setSelectedPublicationId
                                }}
                            />
                        </LayoutField>
                        <LayoutField left={1} top={5} width={3} height={1}>
                            <LookupEditor
                                id='select-template-source'
                                dataSource={customTemplatesDataSource}
                                label='Sjabloon'
                                placeholder="Kies…"
                                displayExpr='label'
                                valueExpr='value'
                                value={selectedTemplateId}
                                editorSettings={{
                                    clearable: true,
                                    onChange: setSelectedTemplateId
                                }}
                            />
                        </LayoutField>
                    </>
                }
                {!shouldImportTemplate.value &&
                    <LayoutField left={1} top={4} width={7} height={1} key={pageDesignFieldDefinition.id}>
                        <TemplateSelectElement
                            id={pageDesignFieldDefinition.id}
                            label={pageDesignFieldDefinition.label ? pageDesignFieldDefinition.label : pageDesignFieldDefinition.name}
                            editorSettings={ValidationUtils.getEditorSettings(true, false, validators, form, (val: string) => { storeFormValue(val, SystemFieldDefinitions.Pub.PageDesignId) }, SystemFieldDefinitions.Pub.PageDesignId)}
                            items={pageDesigns}
                            size='small'
                            value={form.values[SystemFieldDefinitions.Pub.PageDesignId]}
                        />
                    </LayoutField>}
            </LayoutForm>
        </ModalDialog>
    );
};

const radioOptions: IDataItemProps<boolean>[] = [
    { value: false, label: 'Nee, ik wil graag een leeg sjabloon aanmaken' },
    { value: true, label: 'Ja, importeer een sjabloon uit een andere publicatie' },
];

const initForm = (): FormData<string> => {
    return {
        values: {
            [SystemFieldDefinitions.Pub.Name]: '',
            [SystemFieldDefinitions.Pub.PageDesignId]: '',
        },
        touched: {},
        validationErrors: {},
        isValid: true
    };
};

const getValidators = (fieldDefinitions: Record<string, Domain.Shared.FieldDefinition>): ValidatorsDictionary => {
    if (!fieldDefinitions) return {};
    return {
        [SystemFieldDefinitions.Pub.Name]: new TextValidator({
            required: fieldDefinitions[SystemFieldDefinitions.Pub.Name].required,
            stringMaxLength: fieldDefinitions[SystemFieldDefinitions.Pub.Name].stringMaxLength,
            stringMinLength: fieldDefinitions[SystemFieldDefinitions.Pub.Name].stringMinLength,
            stringType: fieldDefinitions[SystemFieldDefinitions.Pub.Name].stringType
        }),
        [SystemFieldDefinitions.Pub.PageDesignId]: new BasicValidator<string>({
            required: fieldDefinitions[SystemFieldDefinitions.Pub.PageDesignId].required
        }),
    }
};

export { TemplateAdd };
