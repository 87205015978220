import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';
import { oDataResponseStructured } from '../../Shared';

/**
 * Represents a data accessor of budget overview data.
 */
export class BudgetOverview {

    /**
     * Gets the Budget overview results V2.
     * 
     * @param baseYear Year on which to filter the budget results.
     * @param structureRK Structure rk on which to filter the budget results.
     * @param structureNodeRK Structure Node rk on which to filter the budget results.
     */
    public static getV2 = async (baseYear: number, structureRK: string, structureNodeRK: string, costBenefitFilter: string[]): Promise<AxiosResponse<oDataResponseStructured<Domain.Finance.BudgetOverviewValue>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<oDataResponseStructured<Domain.Finance.BudgetOverviewValue>>(`/api/finance/budget/overview2`, {
            baseYear,
            structureRK,
            structureNodeRK,
            costBenefitFilter
        }, config);
    };

    /**
     * Gets the Budget drilldown overview results by journalElementKind.
     * 
     * @param journalElementKindRK Kirnal element kins rk on which to filter the budget results.
     * @param transactionKind Name of the transactionKinf (Lasten/Baten).
     * @param journalElementRKs Array of journalelementsRk's already visited.
     */
    public static getJournalElementKindDrilldown = async (baseYear: number, journalElementKindRK: string, transactionKind: string, journalElementRKs: string[]): Promise<AxiosResponse<oDataResponseStructured<Domain.Finance.BudgetOverviewValue>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<oDataResponseStructured<Domain.Finance.BudgetOverviewValue>>(`/api/finance/budget/overview2/drilldown/journalelementkind`, {
            baseYear,
            journalElementKindRK,
            transactionKind,
            journalElementRKs
        }, config);
    };

    /**
     * Gets the budget overview journal element drill down to the JournalCombination and transaction kind (cost/benefit).
     * 
     * @param transactionKind Name of the transactionKinf (Lasten/Baten).
     * @param journalElementRKs Array of journalelementsRk's already visited.
     */
    public static getJournalCombinationDrilldown = async (baseYear: number, transactionKind: string, journalElementRKs: string[]): Promise<AxiosResponse<oDataResponseStructured<Domain.Finance.BudgetOverviewValue>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.post<oDataResponseStructured<Domain.Finance.BudgetOverviewValue>>(`/api/finance/budget/overview2/drilldown/journalcombination`, {
            baseYear,
            transactionKind,
            journalElementRKs
        }, config);
    };

    /**
     * Gets the budget overview structurenode drill down to the JournalCombination and transaction kind (cost/benefit).
     * 
     * @param baseYear The current baseyear.
     * @param structureRK The structure rk. 
     * @param structureNodeRK The structure node rk.
     * @param transactionKind Name of the transactionKinf (Lasten/Baten).
     */
    public static getJournalCombinationDrilldownNode = async (baseYear: number, structureRK: string, transactionKind: string, structureNodeRK?: string,): Promise<AxiosResponse<oDataResponseStructured<Domain.Finance.BudgetOverviewValue>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.post<oDataResponseStructured<Domain.Finance.BudgetOverviewValue>>(`api/finance/budget/overview2/structurenode/journalcombination`, {
            baseYear,
            structureRK,
            structureNodeRK,
            transactionKind
        }, config);
    };

    /**
     * Gets the the budget overview drill down to the JournalCombination and transaction kind (cost/benefit).
     * 
     * @param baseYear The current baseyear.
     * @param structureRK The structure rk. 
     * @param structureNodeRK The structure node rk.
     * @param costBenefitFilter Array of costBenefit.            
     */
    public static getBudgetOverviewStructureNodes = async (baseYear: number, structureRK: string, costBenefitFilter: string[], structureNodeRK?: string): Promise<AxiosResponse<oDataResponseStructured<Domain.Finance.BudgetOverviewValue>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<oDataResponseStructured<Domain.Finance.BudgetOverviewValue>>(`/api/finance/budget/overview2/structurenode`, {
            baseYear,
            structureRK,
            structureNodeRK,
            costBenefitFilter
        }, config);
    };

    /**
     * Gets the transactions for a journal element or journal combination.
     * 
     * @param journalElementRKs Array of journalelementsRk's already visited.
     * @param transactionKind Name of the transactionKinf (Lasten/Baten).
     * @param amountType Amount type.
     */
    public static getTransactions = async (journalElementRKs: string[], transactionKind: string, amountType: string): Promise<AxiosResponse<oDataResponseStructured<Domain.Finance.BudgetOverviewTransaction>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<oDataResponseStructured<Domain.Finance.BudgetOverviewTransaction>>(`/api/finance/budget/overview2/transactions`, {
            journalElementRKs,
            transactionKind,
            amountType,
        }, config);
    };

    /**
     * Gets the transactions for a structurenode.
     * 
     * @param structureRK The structure rk. 
     * @param structureNodeRK The structure node rk.
     * @param transactionKind Name of the transactionKinf (Lasten/Baten).
     * @param amountType Amount type.
     */
    public static getNodeTransactions = async (structureRK: string, structureNodeRK: string, transactionKind: string, amountType: string): Promise<AxiosResponse<oDataResponseStructured<Domain.Finance.BudgetOverviewTransaction>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<oDataResponseStructured<Domain.Finance.BudgetOverviewTransaction>>(`/api/finance/budget/overview2/structurenode/transactions`, {
            structureRK,
            structureNodeRK,
            transactionKind,
            amountType,
        }, config);
    };

}

export default BudgetOverview;