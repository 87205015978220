import React from 'react';
import * as Domain from '@liasincontrol/domain';
import { FlexBox, Heading3, IconSize, OverflowEllipsis, ResetZIndex, Section } from '@liasincontrol/ui-basics';
import { GridColumn, LoadPanel, LsGrid } from '@liasincontrol/ui-devextreme';
import { ApiErrorReportingHelper, DataUtils, IconHelper } from '@liasincontrol/core-service';
import { formatText } from '.';

export type TransactionsType = { gridTitle?: string; layout?: Domain.Finance.BudgetOverviewTransactionLayout[], data?: Domain.Finance.BudgetOverviewTransaction[] };

export interface TransactionProps {
    transactions?: TransactionsType;
    loading?: boolean;
    divideBy?: number;
    onError?: (error: Domain.Shared.ErrorInfo) => void;
}

const extraWidthColumns = /(name$|^description$)/i;

const mapColumnProperties = (layoutColumn, divideBy = 1) => {
    const colProps: GridColumn<Domain.Finance.BudgetOverviewTransaction> = {
        width: "auto",
        align: DataUtils.getDefaultDxAlignment(layoutColumn.dataType),
        dataType: DataUtils.mapStringToDxType(layoutColumn.dataType, false, false),
    };

    if (layoutColumn.dataField === 'Amount') {
        colProps.customizeText = (cellInfo) => formatText(cellInfo, divideBy);
        colProps.width = 100;
    } else if (layoutColumn.dataField === 'WorkflowStep') {
        colProps.renderCustom = (item) => {
            return (<FlexBox>
                {IconHelper.getWorkFlowStatusIcon(item.data.workflowStep || item.data.editStage, IconSize.small)}
                <OverflowEllipsis>
                    {item.data.workflowStep || item.data.editStage}
                </OverflowEllipsis>
            </FlexBox>);
        };
    } else if (extraWidthColumns.test(layoutColumn.dataField)) {
        colProps.width = undefined;
        colProps.minWidth = 160;
    }
    if (layoutColumn.dataType === 'date') {
        colProps.formatter = 'date';
    }

    return colProps;
}

export const Transactions: React.FC<TransactionProps> = (props) => {
    if (!props.transactions?.data) return null;

    const availableColumns = props.transactions.layout.map((col) => {
        return {
            name: col.dataField.charAt(0).toLowerCase() + col.dataField.slice(1),
            title: col.caption,
            ...mapColumnProperties(col, props.divideBy),
        }
    });

    return (<Section look='white'>
        <LoadPanel visible={props.loading} />
        <ResetZIndex>
            <Heading3>{props.transactions?.gridTitle}</Heading3>
            <LsGrid
                id='transactions-grid'
                searching={true}
                showRowLines={true}
                showColumnLines={true}
                showBorders={true}
                allowColumnResizing={true}
                columnAutoWidth={false}
                enableColumnChooser={true}
                columns={availableColumns}
                dataSource={props.transactions?.data}
                onDataError={(exception) => props.onError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception))}
                showCellHintText={true}
                getHintText={(columnField: string, value: string, data: any) => {
                    if (columnField === 'editStage')
                        return data.workflowStep || data.editStage;
                    return value;
                }}
            />
        </ResetZIndex>
    </Section>);
}