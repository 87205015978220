import React, { useRef } from 'react';
import { defaultPageSizes, generateSafeId, minColumnWidth, numberFormat } from '../..';
import DataGrid, {
    Column, ColumnChooser, Paging, RowDragging, Selection, Position,
    ColumnChooserSelection, Editing, Toolbar, Item,
    Summary
} from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import * as TypeFormatter from '../helper/formatters';
import { RequiredRule, NumericRule, RangeRule, StringLengthRule, CustomRule, CompareRule, PatternRule, EmailRule, AsyncRule } from 'devextreme/common';
import { SummaryGroupItem, SummaryTotalItem } from 'devextreme/ui/data_grid';
import Tooltip from "devextreme-react/tooltip"
import { on } from "devextreme/events";
import { formatNumber } from 'devextreme/localization';

export type FieldNameOf<T> = keyof T & string;

export interface GridColumn<T> {
    title?: string;
    caption?: string;
    name?: FieldNameOf<T>;
    type?: "buttons" | "selection" | "adaptive" | "detailExpand" | "groupExpand" | "drag";
    hidden?: boolean;
    renderCustom?: (item: any) => React.ReactElement;
    width?: number | string;
    minWidth?: number;
    order?: number;
    align?: `left` | `right` | `center`;
    groupIndex?: number;
    groupCellRender?: (item: any) => React.ReactElement;
    dataType?: "string" | "number" | "boolean" | "object" | "date" | "datetime";
    formatter?: "date" | "datetime" | "integer" | "decimal";
    hideInColumnChooser?: boolean;
    allowSorting?: boolean;
    calculateSortValue?: string | ((rowData: any) => any);
    calculateDisplayValue?: string | ((rowData: any) => any);
    columns?: GridColumn<T>[];
    defaultSortIndex?: number;
    defaultSortOrder?: "asc" | "desc";
    encodeHtml?: boolean;
    allowEditing?: boolean;
    validationRules?: Array<RequiredRule | NumericRule | RangeRule | StringLengthRule | CustomRule | CompareRule | PatternRule | EmailRule | AsyncRule>;
    editCellTemplate?: (item: any) => React.ReactElement;
    customizeText?: (cellInfo: any) => any;
    isClickable?: boolean;
}
interface GridPaging {
    readonly totalCount?: number;
    readonly currentPage?: number;
    readonly pageSize?: number;
}
interface GridScrolling {
    readonly mode?: "infinite" | "standard" | "virtual";
    readonly columnRenderingMode?: "standard" | "virtual";

    readonly preloadEnabled?: boolean;
    readonly renderAsync?: boolean;
    readonly rowRenderingMode?: "standard" | "virtual";
    readonly scrollByContent?: boolean;
    readonly scrollByThumb?: boolean;
    readonly showScrollbar?: "always" | "never" | "onHover" | "onScroll";
    readonly useNative?: boolean | "auto";
}
interface GridRowDragging {
    readonly allowReordering?: boolean,
    readonly autoScroll?: boolean,
    readonly showDragIcons?: boolean,
    readonly dragDirection?: 'both' | 'horizontal' | 'vertical',
    readonly dropFeedbackMode?: 'push' | 'indicate',
}
interface Props<T> {
    /**
     * id
     */
    readonly id?: string;

    /**
     * Data source
     */
    readonly dataSource: DataSource | T[];

    /**
     * Column chooser visible
     */
    readonly enableColumnChooser?: boolean;

    /**
     * Column auto width
     */
    readonly columnAutoWidth?: boolean;

    /**
     * Set focus on specific rows by keys
     */
    readonly focusedRowKeys?: string[];

    /**
     * Set bold on specific rows by keys
     */
    readonly boldedRowKeys?: string[];

    /**
     * Column settings
     */
    readonly columns?: Array<GridColumn<T>>;

    /**
     * Paging settings
     */
    readonly paging?: GridPaging;

    /**
     * Specifies the expanded groups.
     */
    readonly expandedGroups?: Array<string>;

    /**
     * Row clicked
     */
    readonly onClickRow?: (item: T, value?: any, column?: any) => void;

    /**
     * Cell clicked
     */
    readonly onClickCell?: (item: T, value?: any, column?: any) => void;

    /**
     * Selection mode
     */
    readonly selectionMode?: 'single' | 'multiple' | null;

    /**
     * Active selection
     */
    readonly selectedRowKeys?: (string | number)[];
    readonly onSelectionChanged?: (selection: (string | number)[]) => void;

    /**
     * Message to display when the grid has no data. 
     */
    readonly noDataMessage?: string;

    readonly hideTableHeader?: boolean;

    /**
     * Searching settings
     */
    readonly searching?: boolean;
    readonly searchPanelFullWidth?: boolean;
    readonly onSearching?: (searchValue: string) => void;

    /**
     * Row Drag & drop settings
     */
    readonly rowDragging?: GridRowDragging;
    readonly onReorder?: (items: any) => void;

    /**
     * Border setting
     */
    readonly showBorders?: boolean,
    readonly showColumnLines?: boolean,
    readonly showRowLines?: boolean,

    readonly keyExpr?: string | string[];
    readonly buttonColumnId?: string;

    readonly scrolling?: GridScrolling;

    readonly allowColumnResizing?: boolean;

    readonly onDataError?: (error: any) => void;

    /**
     * Export table
    */
    readonly export?: boolean;
    readonly onExporting?: (workbook: any) => void;

    /**
     * Configures editing.
     */
    readonly editable?: boolean;
    readonly editing?: {
        mode: 'row' | 'cell' | 'form' | 'popup' | 'batch',
        allowUpdating?: boolean,
        allowDeleting?: boolean,
        allowAdding?: boolean,
        texts?: any,
        form?: any,
        onChangesChange?: (changes) => void,
        onSave?: (changes) => void,
    }

    /**
     * Cell hint text settings
     */
    readonly showCellHintText?: boolean;
    readonly getHintText?: (columnField: string, originalValue: string, data: any) => string;

    readonly customToolbarItems?: React.ReactElement[];

    readonly remoteOperations?: {
        filtering?: boolean;
        groupPaging?: boolean;
        grouping?: boolean;
        paging?: boolean;
        sorting?: boolean;
        summary?: boolean;
    };
    readonly groupSummaries?: SummaryGroupItem[];
    readonly totalSummaries?: SummaryTotalItem[];
}

const formatters = {
    'integer': TypeFormatter.integerFormatter,
    'decimal': TypeFormatter.decimalFormatter,
    'date': TypeFormatter.dateFormatter,
    'datetime': TypeFormatter.dateTimeFormatter,
}

export const LsGrid = <T extends {}>(props: Props<T>) => {
    const tooltipRef = useRef(null);
    const getColumns = (column: GridColumn<T>) => {
        const safeId = generateSafeId(column.title);
        if (column.columns) {
            return (<Column key={`group-column-${safeId}`} caption={column.title} visible={!column.hidden}>
                {column.columns.filter(column => !column.hidden).map((column) => getColumns(column))}
            </Column>);
        }
        return getSimpleColumn(column);
    };

    const getSimpleColumn = (column: GridColumn<T>) => {
        const safeId = generateSafeId(column.name);
        return (<Column
            key={`column-${safeId}`}
            width={column.width}
            minWidth={column.minWidth ?? minColumnWidth}
            alignment={column.align}
            caption={column.title}
            dataField={column.name}
            type={column.type}
            cellRender={column.renderCustom}
            allowSorting={column.allowSorting}
            groupIndex={column.groupIndex}
            sortIndex={column.defaultSortIndex}
            sortOrder={column.defaultSortOrder}
            groupCellRender={column.groupCellRender}
            dataType={column.dataType}
            showInColumnChooser={!column.hideInColumnChooser}
            calculateSortValue={column.calculateSortValue}
            calculateDisplayValue={column.calculateDisplayValue}
            format={column.formatter ? formatters[column.formatter] : undefined}
            allowEditing={column.allowEditing || false}
            visible={!column.hidden}
            encodeHtml={column.encodeHtml}
            validationRules={column.validationRules}
            editCellRender={column.editCellTemplate}
            customizeText={column.customizeText}
        />);
    };

    return (<>
        <DataGrid
            id={props.id}
            className={`liasDatagrid${!!props.onClickRow || !!props.onClickCell ? ' row-cursor-pointer' : ''}${props.searchPanelFullWidth ? ' full-width-searchpanel' : ''}`}
            columnAutoWidth={props.columnAutoWidth || false}
            dataSource={props.dataSource}
            showBorders={props.showBorders || false}
            showColumnLines={props.showColumnLines || false}
            showRowLines={props.showRowLines || false}
            scrolling={props.scrolling}
            keyExpr={props.keyExpr}
            showColumnHeaders={!props.hideTableHeader}
            remoteOperations={{ ...defaultRemoteOperations, ...props.remoteOperations }}
            hoverStateEnabled={true}
            allowColumnResizing={props.allowColumnResizing}
            columnResizingMode='widget'
            width='100%'
            repaintChangesOnly={false}
            pager={{
                displayMode: 'compact',
                allowedPageSizes: defaultPageSizes,
                showNavigationButtons: true,
                showPageSizeSelector: true,
                visible: !!props.paging,
                showInfo: true,
            }}
            onCellClick={(e) => {
                if (e.rowType === 'data' && e.column.type !== 'buttons') {
                    if (props.onClickRow)
                        props.onClickRow(e.data, e.value, e.column);
                    else
                        props.onClickCell?.(e.data, e.value, e.column);
                }
            }}
            onContentReady={(e) => {
                //https://js.devexpress.com/Roadmap/#HeaderFilter-and-ColumnChooser-Popup-Customization
                //https://supportcenter.devexpress.com/ticket/details/t676296/datagrid-how-to-change-an-initial-position-of-a-column-chooser
                const gridInstance = e.component as any;
                const columnChooserView = gridInstance.getView("columnChooserView");
                if (!columnChooserView._popupContainer) {

                    columnChooserView._initializePopupContainer();

                    columnChooserView._popupContainer.option("hideOnOutsideClick", true);
                    columnChooserView._popupContainer.option("hideOnParentScroll", true);

                    columnChooserView._popupContainer.option("height", "auto");
                    columnChooserView._popupContainer.option("maxHeight", "50vh");

                    columnChooserView._popupContainer.option("showCloseButton", false);
                    columnChooserView.render();
                }
            }}
            onOptionChanged={(e) => {
                if (props.enableColumnChooser && e.name === "columns") {
                    const path = e.fullName.split(".");
                    const optionName = path[path.length - 1];

                    const buttonColumnId = props.buttonColumnId || 'id';
                    if (optionName === "visible" && e.value === false) {
                        const cols = e.component.getVisibleColumns();
                        const count = cols.filter((c) => c.type !== "buttons").length;

                        if (!!cols.length && count === 0) {
                            e.component.columnOption(buttonColumnId, "visible", false);
                        }
                    }
                    if (e.value === true && !e.component.columnOption(buttonColumnId, "visible")) {
                        e.component.columnOption(buttonColumnId, "visible", true);
                    }
                }
            }}
            onRowPrepared={(e) => {
                if ((props.focusedRowKeys || props.boldedRowKeys) && e.rowType === "data") {
                    if (props.focusedRowKeys && props.focusedRowKeys.includes(e.key)) {
                        e.rowElement.className += " focused-row";
                    }
                    if (props.boldedRowKeys && props.boldedRowKeys.includes(e.key)) {
                        e.rowElement.className += " bolded-row";
                    }
                }
            }}
            onCellPrepared={(e) => {
                if (props.showCellHintText && !!props.getHintText
                    && (e.rowType === "data" || e.rowType === "group" || e.rowType === "groupFooter" || e.rowType === "totalFooter")
                    && !!e.column.caption) {
                    on(e.cellElement, "mouseover", arg => {
                        let displayedContent = '';
                        if (e.rowType === "data" && !!e.displayValue)
                            displayedContent = props.getHintText(e.column.name, e.column.dataType === "number" ? formatNumber(e.displayValue, numberFormat) : e.displayValue, e.data)
                        if (e.rowType === "group" || e.rowType === "groupFooter" || e.rowType === "totalFooter")
                            displayedContent = e['summaryItems']?.filter(s => !!s.value).map(summary => formatNumber(summary.value, numberFormat)).join(', ');

                        if (!!displayedContent && displayedContent.length > 0) {
                            tooltipRef?.current?.instance()?.option("contentTemplate", () => (`<div class='tooltipContent'><div>${displayedContent}</div></div>`));
                            tooltipRef?.current?.instance()?.show(arg.target);
                        }
                    });

                    on(e.cellElement, "mouseout", arg => {
                        tooltipRef?.current?.instance()?.hide();
                    });
                }

            }}
            searchPanel={{
                visible: !!props.searching,
                placeholder: 'Zoeken...',
                searchVisibleColumnsOnly: true,
            }}
            selectedRowKeys={props.selectedRowKeys}
            onSelectionChanged={(e) => props.onSelectionChanged?.(e.selectedRowKeys)}
            onDataErrorOccurred={(e) => props.onDataError?.(e.error)}
            noDataText={props.noDataMessage || 'Geen gegevens beschikbaar'}
            onSaved={props.editing?.onSave}
        >
            {props.selectionMode && <Selection allowSelectAll={false} mode={props.selectionMode} />}
            {props.rowDragging && <RowDragging {...props.rowDragging} onReorder={(e) => { e.promise = props.onReorder?.(e); }} />}
            {
                props.editable && <Editing
                    mode={props.editing?.mode}
                    allowUpdating={props.editing?.allowUpdating || false}
                    allowAdding={props.editing?.allowAdding || false}
                    allowDeleting={props.editing?.allowDeleting || false}
                    startEditAction='click'
                    selectTextOnEditStart={true}
                    onChangesChange={(e) => props.editing?.onChangesChange?.(e)}
                    texts={props.editing?.texts}
                    form={props.editing?.form}
                />
            }
            <ColumnChooser title="" enabled={props.enableColumnChooser} mode='select'>
                <Position my="right top" at="right bottom" of={`${props.id ? `#${props.id}` : ''} .dx-datagrid-column-chooser-button`} collision="fit" />
                <ColumnChooserSelection allowSelectAll={false} selectByClick={true} recursive={true} />
            </ColumnChooser>
            <Toolbar>
                <Item name="groupPanel" />
                {props.customToolbarItems}
                <Item name="exportButton" />
                <Item name="columnChooserButton" />
                <Item name="searchPanel" locateInMenu="auto" />
            </Toolbar>
            {props.columns && props.columns.map(getColumns)}
            {(props.groupSummaries || props.totalSummaries) && <Summary texts={defaultSummaryTexts} groupItems={props.groupSummaries} totalItems={props.totalSummaries} />}
            <Paging enabled={!!props.paging} defaultPageSize={props.paging?.pageSize} />
            {/* {props.export && <Export enabled={true} allowExportSelectedData={false} />} */}
        </DataGrid >
        {props.showCellHintText && <Tooltip ref={tooltipRef} position="right" />}
    </>
    );
};

//By default every operations on the raw data is done in the BE. This settings can be overwritten by the datagrid property.
const defaultRemoteOperations = {
    filtering: true,
    groupPaging: true,
    grouping: true,
    paging: true,
    sorting: true,
    summary: true,
};

const defaultSummaryTexts = { sum: '{0}', 'avg': '{0}', 'count': '{0}', 'max': '{0}', 'min': '{0}' };

// Export the 'Item' component
export { Item as LsGridToolbarItem };