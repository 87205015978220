import React, { useEffect, useState } from 'react';
import { Button, LayoutField, LayoutForm } from '@liasincontrol/ui-basics';
import { MultiLineTextElement } from '@liasincontrol/ui-elements';
import * as Domain from '@liasincontrol/domain';
import { FormHelper, TextValidator, ValidationErrorData } from '@liasincontrol/core-service';
import _ from 'lodash';

type PageImageSettingsProps = {
    readonly sitemapNode: Domain.Publisher.SitemapNode,
    readonly disabled: boolean,
    readonly updateDescription: (newDescription: string) => Promise<void>,
    readonly isEditing: () => void,
}

export const PageDescriptionSettings: React.FC<PageImageSettingsProps> = (props) => {
    const [pageDescription, setPageDescription] = useState<{ isEditing: boolean, value: string }>({ isEditing: false, value: props.sitemapNode.description });
    const [validationErrors, setValidationErrors] = useState<{
        errors: Record<string, ValidationErrorData[]>,
        hasErrors: boolean,
    }>({ errors: {}, hasErrors: false });

    useEffect(() => {
        if (props.sitemapNode.description === pageDescription.value) return;

        setPageDescription({ isEditing: false, value: props.sitemapNode.description });
    }, [props.sitemapNode]);

    const onSaveNewPageDescription = (pageDescription: string) => {
        props.updateDescription(pageDescription)
            .then(() => setPageDescription({ isEditing: false, value: pageDescription }));
    };

    return (
        <LayoutForm align='center'>
            <LayoutField left={1} top={1} width={5} height={1}>
                <MultiLineTextElement
                    id='description-field'
                    label='Pagina omschrijving'
                    rows={2}
                    editorSettings={{
                        disabled: !pageDescription.isEditing,
                        validationErrors: validationErrors.errors["pageDescription"],
                        restrictions: { required: false, minLength: 2, maxLength: 50 },
                        onChange: (value: string) => {
                            if (value === props.sitemapNode.description) {
                                return;
                            }
                            setPageDescription({ isEditing: true, value });
                            const temporaryValidationError = _.cloneDeep(validationErrors);
                            const validationResult = validatePageDescription(value, validationErrors.errors);
                            temporaryValidationError.errors['pageDescription'] = validationResult.errors['pageDescription'];
                            temporaryValidationError.hasErrors = validationResult.hasErrors;
                            setValidationErrors(temporaryValidationError);
                        },
                    }}
                    value={pageDescription.value}
                />
            </LayoutField>
            <LayoutField left={6} top={1} width={1} height={1} >
                {pageDescription.isEditing ?
                    <Button
                        id="btn-icon-save-title"
                        btnbase="ghostbuttons"
                        btntype="small_noicon"
                        disabled={validationErrors.hasErrors}
                        onClick={() => onSaveNewPageDescription(pageDescription.value)}
                    >
                        Opslaan
                    </Button>
                    : <Button
                        id="btn-icon-change-title"
                        btnbase="ghostbuttons"
                        btntype="small_noicon"
                        disabled={props.disabled}
                        onClick={() => {
                            setPageDescription({ isEditing: true, value: props.sitemapNode.description });
                            props.isEditing?.();
                        }}
                    >
                        Bewerken
                    </Button>}
            </LayoutField>
        </LayoutForm>
    );
};

const validatePageDescription = (pageDescription: string, errors: Record<string, ValidationErrorData[]>) => {
    return FormHelper.validateForm(validators, { pageDescription }, errors);
};

// Needed?
const validators = {
    'pageDescription': new TextValidator({ required: false, stringMaxLength: 50, stringType: Domain.Shared.StringType.SingleLine }),
};
