import React, { useState } from 'react';
import { Button, IconSize } from '@liasincontrol/ui-basics';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { HelpText } from '../helptext';
import { Feedback, Label, MandatoryIcon } from '../primitives';
import Styled from './index.styled';

type FullHeightWrapperProps = {
    fullHeight?: boolean,
    children?: React.ReactNode
};

const FullHeightWrapper: React.FC<FullHeightWrapperProps> = (props) => (
    <Styled.HoverContainer fullHeight={props.fullHeight}>
        {props.children}
    </Styled.HoverContainer>
);

type LabelProps = {
    id: string,
    label?: string | JSX.Element,
}

const LabelElement: React.FC<LabelProps> = (props) => props.label ? (
    <Label id={`${props.id}-label`} htmlFor={props.id} text={props.label} />
) : <span />

type HelpTextProps = {
    helpText?: { title?: string, text?: string } | null
}

const HelpTextElement: React.FC<HelpTextProps> = (props) => props.helpText && props.helpText.text
    ? (<HelpText title={props.helpText.title}>{props.helpText.text}</HelpText>)
    : null;

type MaximizeProps = {
    id: string,
    canMaximize?: boolean,
    maximized?: boolean,
    onMaximize?: () => void
}

const MaximizeElement: React.FC<MaximizeProps> = (props) => {
    if (!props.canMaximize) {
        return null;
    }
    const maximizeIcon = props.maximized
        ? <CloseFullscreenIcon sx={{ fontSize: IconSize.default }} />
        : <OpenInFullIcon sx={{ fontSize: IconSize.default }} />

    return (<Button
        title={props.maximized ? 'Minimaliseren' : 'Maximaliseren'}
        className='maximize-button ml-025'
        key={`btn-maximize-${props.id}`}
        btnbase='iconbuttons'
        btntype='small_transparent'
        icon={maximizeIcon}
        aria-label={props.maximized ? 'Minimaliseren' : 'Maximaliseren'}
        onClick={props.onMaximize} />
    );
};

type FeedbackProps = {
    error?: string | React.ReactNode,
    withCounter?: boolean,
    feedback?: React.ReactNode;
    children?: React.ReactNode;
    counter?: React.ReactNode;
    id?: string;
}

export type WithFieldBaseType<V> = FullHeightWrapperProps & LabelProps & HelpTextProps & FeedbackProps & MaximizeProps & {
    as?: React.ElementType;
    value: V,
    disabled?: boolean,
    mandatory?: boolean,
    success?: boolean;
    onFocus?: () => void,
    onBlur?: () => void,
    onInput?: (e: any) => void,
    onChange?: (value: V, previousValue?: V) => void;
    className?: string;
    withoutFeedback?: boolean;
    readOnly?: boolean;
}

export const withField1 = <V, U extends WithFieldBaseType<V>>(Component: React.ComponentType<Omit<U, "as">>) => (props: U) => {
    const { as: Container = React.Fragment } = props;
    const onFocus = () => {
        setFocused(true);
        if (props.onFocus) {
            props.onFocus();
        }
    };
    const onBlur = () => {
        setFocused(false);
        if (props.onBlur) {
            props.onBlur();
        }
    };
    const onChange = (value: V, previousValue?: V) => {
        if (props.onChange) {
            props.onChange(value, previousValue);
        }
    };
    const [focused, setFocused] = useState(false);

    const childProps = {
        onBlur,
        onChange,
        onFocus,
        onFocusIn: onFocus,
        onFocusOut: onBlur,
        onInput: (e) => props.onChange?.(e.event.currentTarget.value),
        field: { ...props, as: Container }, //TODO is this used anywhere???? 
        value: props.value,
        error: !!props.error,
        success: !!props.success,
        focused,
        fullHeight: props.fullHeight,
    };

    return (
        <FullHeightWrapper fullHeight={props.fullHeight}>
            <Styled.LabelContainer>
                <span>
                    <LabelElement id={props.id} label={props.label} />
                    {props.label && <HelpTextElement helpText={props.helpText} />}
                </span>
                <span>
                    {!props.disabled && props.mandatory && <MandatoryIcon />}
                    <MaximizeElement id={props.id} canMaximize={props.canMaximize} maximized={props.maximized} onMaximize={props.onMaximize} />
                </span>
            </Styled.LabelContainer>
            <Component {...{ ...props, ...childProps }} />
            {!!!props.withoutFeedback && <Feedback error={!!props.error} children={props.error} counter={focused ? props.feedback : ''} id={`${props.id}-feedback`} withCounter={isCounterFeedback(props.feedback)} />}
        </FullHeightWrapper>
    );
};

//old
type Sub<O extends PropertyKey, D extends PropertyKey> = {
    [K in O]: (Record<D, never> & Record<PropertyKey, K>)[K]
}[O];
type Omit<O, D extends string> = Pick<O, Sub<keyof O, D>>;

export const withField = <V,>(
    Component: React.ComponentType<
        IFieldChildProps<V> & Omit<IFieldProps<V>, keyof IFieldChildProps<V>>
    >,
): React.ComponentType<Omit<IFieldProps<V>, 'children'>> => {
    return (props: IFieldProps<V>) => (
        <Field {...props}>
            {(render: IFieldChildProps<V>) => <Component {...{ ...props, ...render }} />}
        </Field>
    );
};

export const Field = React.memo(<T,>(props: IFieldProps<T>) => {
    const { as: Container = React.Fragment } = props;
    const onFocus = () => {
        setFocused(true);
        if (props.onFocus) {
            props.onFocus();
        }
    };
    const onBlur = () => {
        setFocused(false);
        if (props.onBlur) {
            props.onBlur();
        }
    };
    const onChange = (value: T) => {
        if (props.onChange) {
            props.onChange(value);
        }
    };
    const [focused, setFocused] = useState(false);

    const childprops = {
        onBlur,
        onChange,
        onFocus,
        field: { ...props, as: Container },
        value: props.value,
        error: !!props.error,
        success: !!props.success,
        focused,
        fullHeight: props.fullHeight,
        // why was not ...props also?
    };

    const children = props.children(childprops);

    const { id, helpText, feedback, disabled, mandatory } = props;
    const label =
        props.label || props.value || focused
            ? props.label || ''
            : '';

    const inputWithCounter = isCounterFeedback(feedback);

    const maximizeIcon = props.maximized
        ? <CloseFullscreenIcon sx={{ fontSize: IconSize.default }} />
        : <OpenInFullIcon sx={{ fontSize: IconSize.default }} />

    return (
        <Styled.HoverContainer fullHeight={props.fullHeight}>
            <Styled.LabelContainer>
                {label ? (
                    <Label id={`${id}-label`} htmlFor={id} text={label} />
                ) : <span />}
                {/* To be sure helptext is placed near the label */}
                {label && helpText && helpText.text && (
                    <HelpText title={helpText.title}>{helpText.text}</HelpText>
                )}
                <span>
                    {!disabled && mandatory && <MandatoryIcon />}
                    {props.canMaximize &&
                        <Button
                            title={props.maximized ? 'Minimaliseren' : 'Maximaliseren'}
                            className='maximize-button ml-025'
                            key={`btn-maximize-${id}`}
                            btnbase='iconbuttons'
                            btntype='small_transparent'
                            icon={maximizeIcon}
                            aria-label={props.maximized ? 'Minimaliseren' : 'Maximaliseren'}
                            onClick={props.onMaximize} />
                    }
                </span>
            </Styled.LabelContainer>
            {children}
            {!props.withoutFeedback && <Feedback
                error={!!props.error}
                children={props.error}
                withCounter={inputWithCounter}
                counter={focused ? props.feedback : ''}
                id={`${id}-feedback`}
            />}
        </Styled.HoverContainer>
    );
});

export interface IFieldChildProps<T> {
    readonly value: T;
    readonly focused: boolean;
    readonly error: boolean;
    readonly success: boolean;
    readonly field: IFieldProps<T>;
    onFocus(): void;
    onBlur(): void;
    onChange(value: T): void;
    readonly fullHeight?: boolean;
}
export interface IFieldProps<T> {
    readonly as?: React.ElementType;
    readonly id: string;
    readonly label?: string | React.ReactNode | null;
    readonly helpText?: { title?: string; text: string } | null;
    readonly placeholder?: string;
    readonly mandatory?: boolean;
    readonly maxTagsCount?: number;
    readonly value: T;
    readonly disabled?: boolean;
    readonly error?: React.ReactNode;
    readonly success?: React.ReactNode;
    readonly feedback?: React.ReactNode;
    readonly altLabel?: string | null;
    readonly maximized?: boolean;
    readonly canMaximize?: boolean;
    readonly onFocus?: () => void;
    readonly onBlur?: () => void;
    readonly onChange?: (value: T) => void;
    readonly onMaximize?: () => void;
    //TODO: unit tests will fail if we don't use any here:
    readonly children: any; // (args: IFieldChildProps<T>) => React.ReactNode;

    readonly fullHeight?: boolean;
    readonly withTextAssistant?: boolean,
    readonly onTextAssistant?: () => void,
    readonly theme?: 'dark' | 'light',
    readonly withoutFeedback?: boolean,
}

/**
 * Matches number/number eg `"10/100"`
 */
const isCounterFeedback = (input: any) =>
    typeof input === 'string' && /^\d+\/\d+$/.test(input);
