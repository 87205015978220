import React, { useMemo } from "react";
import { DropDownElement, MultiSelectElement, SelectElement } from '@liasincontrol/ui-elements';
import { Button, IDataItemProps, LayoutField, LayoutForm } from "@liasincontrol/ui-basics";
import * as Domain from '@liasincontrol/domain';
import { useUserSettings } from '@liasincontrol/core-service';
import { useBaseYears } from "../../shared/hooks";
import { useStructures } from "../../shared/hooks/useStructures";
import { useStructureNodes } from "../../shared/hooks/useStructureNodes";
import Styled from './index.styled';

export type FilterProps = {
    disabled?: boolean;
    costBenefitFilter?: string[];
    setCostBenefitFilter?: (val: string[]) => void;
    onFilter?: () => void;
}

export const costBenefitFilterItems: IDataItemProps<string>[] = [{
    label: 'Lasten',
    value: 'Lasten',
}, {
    label: 'Baten',
    value: 'Baten',
}];

export const Filter: React.FC<FilterProps> = (props: FilterProps) => {
    const baseYears = useBaseYears();
    const structures = useStructures();
    const { structureNodes, fetchStructureNodes } = useStructureNodes();

    const userSettingsContext = useUserSettings();

    const structureOptions = useMemo(() => (structures.items?.filter(item => item.baseYear === userSettingsContext.baseYear)), [structures, userSettingsContext.baseYear]);

    const structureNodeOptions = useMemo(() => (
        userSettingsContext.structureRK && structureNodes[userSettingsContext.structureRK] ?
            structureNodes[userSettingsContext.structureRK].items?.map(item => item.parentRK === userSettingsContext.structureRK ? { ...item, parentRK: null } : item)
            : []
    ), [userSettingsContext.structureRK, structureNodes]);

    return (
        <LayoutForm columns={5} noMinHeight={true}>
            <LayoutField top={1} left={1} width={1} height={1}>
                <SelectElement<number>
                    id='filter-base-year'
                    label='Basisjaar'
                    placeholder='Kies...'
                    optionItems={baseYears.items}
                    value={userSettingsContext.baseYear}
                    clearable={true}
                    searchable={false}
                    editorSettings={{
                        disabled: baseYears.items?.length <= 0,
                        withoutFeedback: true,
                        onChange: (val) => {
                            userSettingsContext.setBaseYear(val);
                        },
                    }}
                />
            </LayoutField>
            <LayoutField top={1} left={2} width={1} height={1}>
                <SelectElement<Domain.Finance.Structure>
                    id='filter-structure'
                    label='Structuren'
                    displayExpr='name'
                    optionItems={structureOptions}
                    value={structureOptions?.find(str => str.rk === userSettingsContext.structureRK)}
                    clearable={true}
                    searchable={false}
                    editorSettings={{
                        disabled: !userSettingsContext.baseYear,
                        withoutFeedback: true,
                        restrictions: { required: false },
                        validationErrors: [],
                        onChange: (val) => {
                            userSettingsContext.setStructureRK(val?.rk);
                            fetchStructureNodes(val?.rk);
                        }
                    }}
                />
            </LayoutField>
            <LayoutField top={1} left={3} width={1} height={1}>
                <DropDownElement<Domain.Finance.StructureNode>
                    id='filter-structurenode'
                    label='Structuuronderdeel'
                    displayExpr='name'
                    valueExpr='rk'
                    keyExpr="rk"
                    parentIdExpr="parentRK"
                    value={structureNodeOptions.find(strn => strn.rk === userSettingsContext.structureNodeRK)}
                    editorSettings={{
                        disabled: !(userSettingsContext.baseYear && userSettingsContext.structureRK),
                        withoutFeedback: true,
                        restrictions: { required: false },
                        validationErrors: [],
                        onChange: (item) => {
                            userSettingsContext.setStructureNodeRK(item?.rk);
                        },
                    }}
                    optionItems={structureNodeOptions}
                    openOnFieldClick={true}
                    clearable={true}
                    searchable={true}
                />
            </LayoutField>
            <LayoutField top={1} left={4} width={1} height={1}>
                <MultiSelectElement
                    id='filter-costs-benefits'
                    label='Lasten/ Baten'
                    placeholder='Kies...'
                    editorSettings={{
                        withoutFeedback: true,
                        disabled: !(userSettingsContext.baseYear && userSettingsContext.structureRK),
                        onChange: (val: IDataItemProps<string>[]) => props.setCostBenefitFilter(val.flatMap(cb => cb.value)),
                    }}
                    searchable={false}
                    clearable={true}
                    optionItems={costBenefitFilterItems}
                    value={props.costBenefitFilter.map(cb => ({ label: cb, value: cb }))}
                />
            </LayoutField>
            <Styled.ButtonLayoutField top={1} left={5} width={1} height={1}>
                <Button
                    disabled={!userSettingsContext.baseYear || !userSettingsContext.structureRK || props.disabled}
                    btnbase="textbuttons"
                    btntype="medium_noicon"
                    onClick={() => props.onFilter()}>
                    Ophalen
                </Button>
            </Styled.ButtonLayoutField>
        </LayoutForm>
    );
};
