import React, { useEffect, useMemo, useState } from 'react';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { AnyFormData, BasicValidator, FormHelper, isMomentOpen, ValidationErrorData, ValidationUtils, ValidatorsDictionary, ValueType } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { ModalDialog, LayoutForm, ModalDialogFooter, LayoutField, RadioGroupField, WarningWrapper, WarningLook, IDataItemProps } from '@liasincontrol/ui-basics';
import { SelectElement } from '@liasincontrol/ui-elements';
import { momentInputCustomOptions } from '../../_shared/OptionItem/MeasureMomentOptionItem';

type Props = {
    hierarchyDefinitions: Record<string, Domain.Shared.HierarchyDefinition>,
    measureMoments: Domain.Shared.MeasureMoment[],
    usedHierarchies: { measureMomentId: string, hiearchyDefinitionIds: string[] }[], //create type!
    selectedHierarchyDefinitionId?: string,
    selectedMomentId?: string,
    isImportDialogSaveButtonActive?: boolean,
    onSave: (data: Record<string, any>) => void,
    onCancel: () => void,
};

/**
 * Represents a UI component that renders the form for creating a hierarchy for a moment.
 */
export const CreateHierarchyForm: React.FC<Props> = (props) => {
    const [form, setForm] = useState<AnyFormData>(initForm());

    useEffect(() => {
        if (!props.hierarchyDefinitions) return;
        if (!props.measureMoments) return;

        const selectedDefinition = Object.values(props.hierarchyDefinitions || {}).find((definition) => definition.hierarchyDefinitionId === props.selectedHierarchyDefinitionId && definition.deleted === false);
        const selectedMoment = props.measureMoments?.find((moment) => moment.id === props.selectedMomentId && isMomentOpen(moment.status));
        const newForm = initForm(selectedDefinition?.hierarchyDefinitionId, selectedMoment?.id);
        setForm(newForm);
    }, [props.selectedHierarchyDefinitionId, props.selectedMomentId, props.hierarchyDefinitions, props.measureMoments]);

    const hierarchyDefinitionOptions = useMemo(() => {
        if (!props.hierarchyDefinitions) return [];
        return Object.values(props.hierarchyDefinitions).filter((definition) => !definition.deleted)
            ?.map((definition) => ({ value: definition.hierarchyDefinitionId, label: definition.name }));
    }, [props.hierarchyDefinitions]);

    const measureMomentOptions = useMemo(() =>
        props.measureMoments?.filter((moment) => isMomentOpen(moment.status))
            ?.map((moment) => ({ value: moment.id, label: moment.name, closed: !isMomentOpen(moment.status) }))
        , [props.measureMoments]);

    const availableSourceMoments = useMemo(() => {
        if (!props.usedHierarchies || !props.measureMoments || !form.values['measureMomentId'] || !form.values['hierarchydefinitionId']) {
            return [];
        }
        const moments = props.usedHierarchies.filter((item) =>
            item.measureMomentId !== form.values['measureMomentId'] &&
            item.hiearchyDefinitionIds.includes(form.values['hierarchydefinitionId'])
        ).map((i) => i.measureMomentId);
        return props.measureMoments.filter(moment => moments.includes(moment.id)).map(m => ({ value: m.id, label: m.name, closed: !isMomentOpen(m.status) }));

    }, [props.usedHierarchies, props.measureMoments, form.values['measureMomentId'], form.values['hierarchydefinitionId']]);

    const alreadyExist = useMemo(() => props.usedHierarchies.some((item) =>
        item.measureMomentId === form.values['measureMomentId'] &&
        item.hiearchyDefinitionIds.includes(form.values['hierarchydefinitionId'])
    ), [props.usedHierarchies, form.values['measureMomentId'], form.values['hierarchydefinitionId']]);

    const validators = getValidators();

    const storeFormValue = (value: ValueType, systemId: keyof typeof validators, resetExternalErrorFieldSystemIds: string[] = []) => {
        setForm((prevForm) => FormHelper.validateAndStoreFormValue<AnyFormData>(prevForm, value, validators, systemId, resetExternalErrorFieldSystemIds));
    };

    const footerElement = (
        <ModalDialogFooter
            leftButtonText='Annuleren'
            onLeftButtonClick={props.onCancel}
            rightButtonText='Aanmaken'
            onRightButtonClick={() => { props.onSave(form.values); }}
            rightButtonDisabled={!form.isValid || alreadyExist || !props.isImportDialogSaveButtonActive}
        />
    );
    return (
        <ModalDialog
            modalDialogStyle="custom"
            settings={{
                look: "interactive",
                title: 'Hiërarchie aanmaken',
                footer: footerElement,
            }}
        >
            {alreadyExist && <WarningWrapper
                look={WarningLook.dangerInverted}
                icon={<WarningAmberOutlinedIcon />}
                className='p-025 mb-100'
                messageText={`Hiërarchie bestaat al.`}
            />}
            <LayoutForm columns={8}>
                <LayoutField key='input-hierarchy-definition-new-hierarchy-field' left={1} top={1} width={8} height={1}>
                    <SelectElement<IDataItemProps<string>>
                        id='input-hierarchy-definition-new-hierarchy'
                        label='Hiërarchiedefinitie'
                        displayExpr='label'
                        valueExpr='value'
                        optionItems={hierarchyDefinitionOptions}
                        value={form.values['hierarchydefinitionId']}
                        clearable={false}
                        searchable={true}
                        editorSettings={{
                            disabled: false,
                            restrictions: { required: true },
                            validationErrors: form.touched['hierarchydefinitionId'] ? form.validationErrors['hierarchydefinitionId'] : [],
                            onChange: (selectedItem) => {
                                storeFormValue(selectedItem, 'hierarchydefinitionId');
                            }
                        }}
                    />
                </LayoutField>
                <LayoutField key='input-input-moment-new-hierarchy' left={1} top={2} width={8} height={1}>
                    <SelectElement<Domain.Shared.MomentItem>
                        id='input-moment-new-hierarchy'
                        label='Moment'
                        optionItems={measureMomentOptions}
                        displayExpr='label'
                        valueExpr='value'
                        value={form.values['measureMomentId']}
                        clearable={false}
                        searchable={true}
                        customOptions={(item) => momentInputCustomOptions({ ...item })}
                        customSingleValue={(item) => momentInputCustomOptions({ ...item }, { isFieldTemplate: true, placeholder: 'Kies...' })}
                        editorSettings={{
                            disabled: false,
                            restrictions: { required: true },
                            validationErrors: form.touched['measureMomentId'] ? form.validationErrors['measureMomentId'] : [],
                            onChange: (selectedItem) => {
                                storeFormValue(selectedItem, 'measureMomentId');
                            }
                        }}
                    />
                </LayoutField>
                <LayoutField key='input-input-import-new-hierarchy' left={1} top={3} width={8} height={1}>
                    <RadioGroupField
                        id='input-import-new-hierarchy'
                        label='Data importeren'
                        items={[
                            { value: false, label: 'Nee, ik wil graag een lege hiërarchie aanmaken' },
                            { value: true, label: 'Ja, importeer data van een eerder moment' },
                        ]}
                        value={form.values['importData']}
                        onChange={(selectedItem: number) => {
                            storeFormValue(selectedItem, 'importData', ['sourceMeasureMomentId']);
                        }}
                    />
                </LayoutField>
                {form.values['importData'] &&
                    <LayoutField key='input-input-source-moment-new-hierarchy' left={1} top={4} width={8} height={1}>
                        <SelectElement<Domain.Shared.MomentItem>
                            id='input-source-moment-new-hierarchy'
                            label=''
                            displayExpr='label'
                            valueExpr='value'
                            optionItems={availableSourceMoments}
                            value={form.values['sourceMeasureMomentId']}
                            clearable={false}
                            searchable={true}
                            customOptions={(item) => momentInputCustomOptions({ ...item })}
                            customSingleValue={(item) => momentInputCustomOptions({ ...item }, { isFieldTemplate: true, placeholder: 'Kies...' })}
                            editorSettings={{
                                disabled: false,
                                restrictions: { required: !!form.values['importData'] },
                                validationErrors: form.touched['sourceMeasureMomentId'] ? form.validationErrors['sourceMeasureMomentId'] : [],
                                onChange: (selectedItem) => {
                                    storeFormValue(selectedItem, 'sourceMeasureMomentId');
                                }
                            }}
                        />
                    </LayoutField>}
            </LayoutForm>
        </ModalDialog>
    );
};

const getValidators = (): ValidatorsDictionary => {
    return {
        'hierarchydefinitionId': new BasicValidator<string>({ required: true }),
        'measureMomentId': new BasicValidator<string>({ required: true }),
        'sourceMeasureMomentId': new BasicValidator<string>({ required: false },
            (value: string, formFields: Record<string, ValueType>): ValidationErrorData[] => {
                if (formFields['importData'] && ValidationUtils.isEmpty(value)) {
                    return [{ error: 'Veld vereist.' }];
                }
                return [];
            }),
    };
};

const initForm = (selectedHierarchyDefinitionId?: string, selectedMomentId?: string): AnyFormData => {
    return {
        values: {
            hierarchydefinitionId: selectedHierarchyDefinitionId || null,
            measureMomentId: selectedMomentId || null,
            importData: false,
            sourceMeasureMomentId: null,
        },
        touched: {},
        validationErrors: {},
        isValid: (!!selectedHierarchyDefinitionId && !!selectedMomentId),
    };
};
