import React, { useEffect, useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { SystemFieldDefinitions } from '@liasincontrol/domain';
import { TextValidator, ValidationUtils, ValidatorsDictionary, FormData, BasicValidator, ValueType, FormMode, FormInfo, FormHelper, AttachmentValidator } from '@liasincontrol/core-service';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import { IDataItemProps, LayoutField, LayoutForm } from '@liasincontrol/ui-basics';
import { AttachmentElement, MultiLineTextElement, SelectElement, TextElement } from '@liasincontrol/ui-elements';
import { dateTimeFormatter } from '@liasincontrol/ui-devextreme';

type Props = {
    dataStore?: Domain.Publisher.DataStoreElement,
    attachments?: Domain.Shared.Attachment[],
    mode: FormMode,
    fieldDefinitions: Record<string, Domain.Shared.FieldDefinition>,
    hierarchyDefinitions: Domain.Shared.HierarchyDefinition[],
    onLoadAttachment: (attachmentId: string) => Promise<Blob>,
    onUploadAttachment: (file: File, abortSignal: AbortSignal) => Promise<string>,
    onFormDataChanged: (formInfo: FormInfo<string>) => void,
};

/**
 * Represents a UI component that renders a datastore viewing/adding/editing form.
 */
const DataStoreForm: React.FC<Props> = (props) => {
    const [form, setForm] = useState<FormData<string>>(initForm(undefined));
    const [dataPlatformViews, setDataPlatformViews] = useState<IDataItemProps<string>[]>([]);

    useEffect(() => {
        if (!props.dataStore || !props.fieldDefinitions) {
            return;
        }
        setForm(initForm(props.dataStore));

        if (props.mode !== FormMode.AddNew) {
            const type = getType(props.fieldDefinitions, props.dataStore.typeId);
            if (type === Domain.Publisher.DataStoreTypes.DataPlatform) {
                loadDataPlatformViews();
            }
        }
    }, [props.dataStore]);

    useEffect(() => {
        if (!form.values || !form.values[SystemFieldDefinitions.Pub.Type]) {
            return;
        }
        const type = getType(props.fieldDefinitions, form.values[SystemFieldDefinitions.Pub.Type]);
        if (type === Domain.Publisher.DataStoreTypes.DataPlatform) {
            loadDataPlatformViews();
        }

    }, [form.values[SystemFieldDefinitions.Pub.Type]]);

    if (!props.fieldDefinitions) {
        return null;
    }
    const selectedType = getType(props.fieldDefinitions, form.values[SystemFieldDefinitions.Pub.Type]);
    const validators = getValidators(props.fieldDefinitions, selectedType);

    const storeFormValue = (value: ValueType, systemId: keyof typeof validators) => {
        // we need to re-compute the validators before each form field change, otherwise we could be using the old values for isTypeCsvSelected
        const currentType = (systemId === SystemFieldDefinitions.Pub.Type
            ? getType(props.fieldDefinitions, value)
            : getType(props.fieldDefinitions, form.values[SystemFieldDefinitions.Pub.Type]));
        const validators = getValidators(props.fieldDefinitions, currentType);

        const newForm = FormHelper.validateAndStoreFormValue<FormData<string>>(form, value, validators, systemId);
        setForm(newForm);
        props.onFormDataChanged({ values: newForm.values, isValid: newForm.isValid, isTouched: Object.keys(newForm.touched).length > 0 });
    };

    const loadDataPlatformViews = (): void => {
        DataAccess.DataStores.getDataPlatformViews()
            .then((response) => {
                const views = response.data.map((item) => ({ value: item.viewName, label: item.viewName }));
                setDataPlatformViews(views);
            });
    };

    const fieldWidth = props.mode === FormMode.View ? 3 : 6;
    const nameDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.Name];
    const typeDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.Type];
    const connectionInformationDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.ConnectionInformation];
    const sourceFileDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.SourceFile];
    const dataPlatformViewDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.DataPlatformView];
    const hierarchyDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.HierarchyDefinition];
    const financeTableDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.FinanceTable];
    const typeList = typeDefinition.optionItems.map(i => { const o = { ...i }; delete o['icon']; return o })

    return (
        <LayoutForm>
            <LayoutField key={nameDefinition.id} left={1} top={1} width={fieldWidth} height={1}>
                <TextElement
                    id={nameDefinition.id}
                    label={nameDefinition.label ? nameDefinition.label : nameDefinition.name}
                    editorSettings={ValidationUtils.getEditorSettings(props.mode !== FormMode.View, false, validators, form, (val: string) => { storeFormValue(val, SystemFieldDefinitions.Pub.Name) }, SystemFieldDefinitions.Pub.Name)}
                    value={form.values[SystemFieldDefinitions.Pub.Name]}
                />
            </LayoutField>
            <LayoutField key={typeDefinition.id} left={1} top={2} width={fieldWidth} height={1}>
                <SelectElement<Domain.Shared.FieldDefinitionOptionItem>
                    id={`ds-select-type-def-${typeDefinition.id}`}
                    label={typeDefinition.label ? typeDefinition.label : typeDefinition.name}
                    displayExpr='name'
                    searchable={false}
                    clearable={false}
                    optionItems={typeList}
                    editorSettings={ValidationUtils.getEditorSettings(props.mode === FormMode.AddNew, false, validators, form, (val) => { storeFormValue(val.id, SystemFieldDefinitions.Pub.Type) }, SystemFieldDefinitions.Pub.Type)}
                    value={typeList.find(ot => ot.id === form.values[SystemFieldDefinitions.Pub.Type])}
                />
            </LayoutField>
            {selectedType === Domain.Publisher.DataStoreTypes.CSV && (
                <>
                    <LayoutField key={connectionInformationDefinition.id} left={1} top={3} width={fieldWidth} height={1}>
                        <MultiLineTextElement
                            id={connectionInformationDefinition.id}
                            label={connectionInformationDefinition.label ? connectionInformationDefinition.label : connectionInformationDefinition.name}
                            rows={10}
                            editorSettings={ValidationUtils.getEditorSettings(props.mode !== FormMode.View, false, validators, form, (val: string) => { storeFormValue(val, SystemFieldDefinitions.Pub.ConnectionInformation) }, SystemFieldDefinitions.Pub.ConnectionInformation)}
                            value={form.values[SystemFieldDefinitions.Pub.ConnectionInformation]}
                        />
                    </LayoutField>
                    <LayoutField key={sourceFileDefinition.id} left={1} top={4} width={fieldWidth} height={1}>
                        <AttachmentElement
                            id={sourceFileDefinition.id}
                            label={sourceFileDefinition.label ? sourceFileDefinition.label : sourceFileDefinition.name}
                            helpText={{ title: sourceFileDefinition.helpTextTitle, text: sourceFileDefinition.helpText }}
                            editorSettings={ValidationUtils.getEditorSettings(props.mode !== FormMode.View, false, validators, form, (val: string) => { storeFormValue(val, SystemFieldDefinitions.Pub.SourceFile) }, SystemFieldDefinitions.Pub.SourceFile)}
                            value={form.values[SystemFieldDefinitions.Pub.SourceFile]}
                            onLoadAttachment={props.onLoadAttachment}
                            onUploadAttachment={props.onUploadAttachment}
                            allowDownload={true}
                        />
                    </LayoutField>
                    <LayoutField key={`date-uploaded-${nameDefinition.id}`} left={1} top={5} height={1} width={fieldWidth}>
                        {props.mode === FormMode.View && props.attachments?.[0] &&
                            <TextElement
                                id={`date-uploaded-${nameDefinition.id}`}
                                label='Datum upload'
                                editorSettings={null}
                                value={dateTimeFormatter(props.attachments[0].uploadedOn)}
                            />
                        }
                    </LayoutField>

                </>
            )}
            {selectedType === Domain.Publisher.DataStoreTypes.DataPlatform && (
                <LayoutField key={dataPlatformViewDefinition.id} left={1} top={3} width={fieldWidth} height={1}>
                    <SelectElement<IDataItemProps<string>>
                        id={`ds-select-dpv-${dataPlatformViewDefinition.id}`}
                        label={dataPlatformViewDefinition.label ? dataPlatformViewDefinition.label : dataPlatformViewDefinition.name}
                        displayExpr='label'
                        searchable={true}
                        clearable={false}
                        optionItems={dataPlatformViews}
                        editorSettings={ValidationUtils.getEditorSettings(props.mode !== FormMode.View, false, validators, form, (val) => { storeFormValue(val.value, SystemFieldDefinitions.Pub.DataPlatformView) }, SystemFieldDefinitions.Pub.DataPlatformView)}
                        value={dataPlatformViews.find(dp => dp.value === form.values[SystemFieldDefinitions.Pub.DataPlatformView])}
                    />
                </LayoutField>
            )}
            {selectedType === Domain.Publisher.DataStoreTypes.Studio && (
                <LayoutField key={hierarchyDefinition.id} left={1} top={3} width={fieldWidth} height={1}>
                    <SelectElement<Domain.Shared.HierarchyDefinition>
                        id={`ds-select-hd-${hierarchyDefinition.id}`}
                        label={hierarchyDefinition.label ? hierarchyDefinition.label : hierarchyDefinition.name}
                        displayExpr='name'
                        searchable={true}
                        clearable={true}
                        optionItems={props.hierarchyDefinitions}
                        editorSettings={ValidationUtils.getEditorSettings(props.mode !== FormMode.View, false, validators, form, (val) => { storeFormValue(val?.hierarchyDefinitionId, SystemFieldDefinitions.Pub.HierarchyDefinition) }, SystemFieldDefinitions.Pub.HierarchyDefinition)}
                        value={props.hierarchyDefinitions.find(hd => hd.hierarchyDefinitionId === form.values[SystemFieldDefinitions.Pub.HierarchyDefinition])}
                    />
                </LayoutField>
            )}
            {selectedType === Domain.Publisher.DataStoreTypes.Finance && (
                <LayoutField key={hierarchyDefinition.id} left={1} top={3} width={fieldWidth} height={1}>
                    <SelectElement<Domain.Shared.FieldDefinitionOptionItem>
                        id={`ds-select-ft-${financeTableDefinition.id}`}
                        label={financeTableDefinition.label ? financeTableDefinition.label : financeTableDefinition.name}
                        displayExpr='name'
                        searchable={true}
                        clearable={true}
                        optionItems={financeTableDefinition.optionItems}
                        editorSettings={ValidationUtils.getEditorSettings(props.mode !== FormMode.View, false, validators, form, (val) => { storeFormValue(val?.id, SystemFieldDefinitions.Pub.FinanceTable) }, SystemFieldDefinitions.Pub.FinanceTable)}
                        value={financeTableDefinition.optionItems.find(hd => hd.id === form.values[SystemFieldDefinitions.Pub.FinanceTable])}
                    />
                </LayoutField>
            )}
        </LayoutForm>
    );
};

const initForm = (dataStore: Domain.Publisher.DataStoreElement): FormData<string> => {
    return {
        values: {
            [SystemFieldDefinitions.Pub.Name]: dataStore?.name ? dataStore.name : '',
            [SystemFieldDefinitions.Pub.Type]: dataStore?.typeId ? dataStore.typeId : '',
            [SystemFieldDefinitions.Pub.ConnectionInformation]: dataStore?.connectionInformation ? dataStore.connectionInformation : '',
            [SystemFieldDefinitions.Pub.SourceFile]: dataStore?.sourceFileId ? dataStore.sourceFileId : '',
            [SystemFieldDefinitions.Pub.DataPlatformView]: dataStore?.dataPlatformView ? dataStore.dataPlatformView : '',
            [SystemFieldDefinitions.Pub.HierarchyDefinition]: dataStore?.hierarchyDefinition ? dataStore.hierarchyDefinition : '',
            [SystemFieldDefinitions.Pub.FinanceTable]: dataStore?.financeTable ? dataStore.financeTable : '',
        },
        touched: {},
        validationErrors: {},
        isValid: false,
    };
};

const getValidators = (fieldDefinitions: Record<string, Domain.Shared.FieldDefinition>, type: Domain.Publisher.DataStoreTypes): ValidatorsDictionary => {
    if (!fieldDefinitions) return {};
    return {
        [SystemFieldDefinitions.Pub.Name]: new TextValidator({
            required: fieldDefinitions[SystemFieldDefinitions.Pub.Name].required,
            stringMaxLength: fieldDefinitions[SystemFieldDefinitions.Pub.Name].stringMaxLength,
            stringMinLength: fieldDefinitions[SystemFieldDefinitions.Pub.Name].stringMinLength,
            stringType: fieldDefinitions[SystemFieldDefinitions.Pub.Name].stringType
        }),
        [SystemFieldDefinitions.Pub.Type]: new BasicValidator<string>({ required: true }),

        //for csv
        [SystemFieldDefinitions.Pub.ConnectionInformation]: new TextValidator({
            required: fieldDefinitions[SystemFieldDefinitions.Pub.ConnectionInformation].required,
            stringMaxLength: fieldDefinitions[SystemFieldDefinitions.Pub.ConnectionInformation].stringMaxLength,
            stringType: fieldDefinitions[SystemFieldDefinitions.Pub.ConnectionInformation].stringType
        }),
        [SystemFieldDefinitions.Pub.SourceFile]: new AttachmentValidator({
            required: type === Domain.Publisher.DataStoreTypes.CSV,
            attachmentAllowedFileTypes: fieldDefinitions[SystemFieldDefinitions.Pub.SourceFile].attachmentAllowedFileTypes,
            attachmentMaxFileSize: fieldDefinitions[SystemFieldDefinitions.Pub.SourceFile].attachmentMaxFileSize
        }),
        //for dataplatform
        [SystemFieldDefinitions.Pub.DataPlatformView]: new BasicValidator<string>({ required: type === Domain.Publisher.DataStoreTypes.DataPlatform }),

        //for studio
        [SystemFieldDefinitions.Pub.HierarchyDefinition]: new BasicValidator<string>({ required: type === Domain.Publisher.DataStoreTypes.Studio }),
    }
};

const getType = (fieldDefinitions: Record<string, Domain.Shared.FieldDefinition>, typeFieldValue: ValueType): number | undefined => {
    if (!fieldDefinitions || ValidationUtils.isEmpty(typeFieldValue)) {
        return undefined;
    }

    return fieldDefinitions[SystemFieldDefinitions.Pub.Type].optionItems.find(item => item.id === typeFieldValue).value;
};

export { DataStoreForm };